.sale-tunnel-error__hide-zone {
  position: absolute;
  left: -10px;
  top: 0px;
  height: 450px;
  width: 625px;
  overflow: hidden;
}

.sale-tunnel-origin,
.sale-tunnel-error {
  position: absolute;
  height: 450px;
  width: 625px;
  left: -625px;
  padding: 12px 0;
  background-color: var(--color-back);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: left;
  border: 3px dotted var(--color-shadow);
}

.sale-tunnel-origin--close,
.sale-tunnel-error--close {
  animation: close-error-detail 0.8s ease forwards;
}

.sale-tunnel-origin--open,
.sale-tunnel-error--open {
  animation: open-error-detail 0.8s ease forwards;
}

@keyframes open-error-detail {
  0% {
    left: -625px;
  }
  100% {
    left: 0px;
  }
}

@keyframes close-error-detail {
  0% {
    left: -0px;
  }
  100% {
    left: -625px;
  }
}

.sale-tunnel-error__tab {
  width: 100%;
  height: calc(100% - 45px);
  padding: 10px 50px;
  display: flex;
  flex-direction: column;
}

.sale-tunnel-error__stats {
  display: table;
  margin-left: 35px;
}

.sale-tunnel-error__line {
  width: 100%;
  height: 100%;
  display: table-row;
}

.sale-tunnel-error__cell {
  display: table-cell;
  width: 360px;
  text-align: left;
  height: 30px;
  vertical-align: middle;
  font-size: 14px;
}

.sale-tunnel-error__cell__numbers {
  display: table-cell;
  width: 60px;
  text-align: right;
  height: 40px;
  vertical-align: middle;
  font-size: 14px;
  padding-right: 5px;
}

.sale-tunnel-error__group__title {
  text-align: left;
  background-color: var(--color-shadow);
  padding: 4px 0;
}

.sale-tunnel-error__group {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  font-size: 15px;
}

.sale-tunnel-error__tab:nth-child(2)
  > .sale-tunnel-error__group
  > .sale-tunnel-error__stats
  > .sale-tunnel-error__line
  > .sale-tunnel-error__cell,
.sale-tunnel-error__tab:nth-child(2)
  > .sale-tunnel-error__group
  > .sale-tunnel-error__stats
  > .sale-tunnel-error__line
  > .sale-tunnel-error__cell__numbers {
  color: var(--color-icon);
  font-size: 12px;
  height: 25px;
}

.sale-tunnel-error__stats-zone {
  overflow-y: auto;
}

.sale-tunnel-error__cell + .sale-tunnel-error__cell__numbers {
  width: 80px;
}

.sale-tunnel-origin > .comparative-select__button,
.sale-tunnel-error > .comparative-select__button {
  width: max-content;
  text-align: left;
  margin-left: 50px;
  border-color: var(--color-valraiso);
}

.sale-tunnel-origin > .comparative-select__button:hover,
.sale-tunnel-error > .comparative-select__button:hover {
  cursor: auto;
}

.sale-tunnel-error__close__button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 35px;
  height: 35px;
  border: 2px solid var(--color-shadow);
}

.sale-tunnel-error__close__button:hover {
  border-color: var(--color-valraiso);
}

.sale-tunnel-error__close__icon {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 25px;
  height: 25px;
  fill: var(--color-icon);
}

.sale-tunnel-error__line--odd {
  background-color: var(--color-nav);
}

.sale-tunnel-origin-pages {
  display: flex;
  margin: 0 50px;
  width: calc(100% - 100px);
  height: 100%;
  flex-direction: column;
}

.sale-tunnel-origin-pages__buttons {
  display: flex;
}

.sale-tunnel-origin-pages__content {
  position: relative;
  top: -2px;
  display: flex;
  width: 100%;
  height: 100%;
  border: 2px solid var(--color-darker-nav);
}

.sale-tunnel-origin-pages__button {
  position: relative;
  z-index: 1;
  border-radius: 0;
  border: 2px solid var(--color-darker-nav);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-left: none;
  background-color: var(--color-nav);
  font-size: 14px;
}

.sale-tunnel-origin-pages__button > span{
  border-bottom: 2px solid var(--color-valraiso);
}

.sale-tunnel-origin-pages__button--on {
  border-bottom: 2px solid var(--color-back);
  background-color: var(--color-back);
}

.sale-tunnel-origin-pages__button:first-of-type {
  border-left: 2px solid var(--color-darker-nav);
  border-top-left-radius: 4px;
}
.sale-tunnel-origin-pages__button:last-of-type {
  border-top-right-radius: 4px;
}

@media (prefers-color-scheme: dark) {
  .sale-tunnel-error__group__title {
    background-color: var(--color-dark-back);
  }
}

@media (max-width: 945px) {
  .sale-tunnel-error__hide-zone {
    position: absolute;
    left: -11px;
    top: 0px;
    height: 345px;
    width: 430px;
    overflow: hidden;
  }
  .sale-tunnel-origin,
  .sale-tunnel-error {
    height: 345px;
    width: 430px;
    left: -430px;
  }
  .sale-tunnel-error__tab {
    padding: 8px 10px 8px 15px;
    height: calc(100% - 30px);
  }
  .sale-tunnel-error__cell {
    width: 250px;
    font-size: 13px;
  }
  .sale-tunnel-error__cell__numbers {
    width: 60px;
    height: 40px;
    font-size: 13px;
  }
  .sale-tunnel-error__close__button {
    width: 30px;
    height: 30px;
  }
  .sale-tunnel-error__close__icon {
    width: 21px;
    height: 21px;
  }
}

@media (max-width: 695px) {
  .sale-tunnel-error__hide-zone {
    position: absolute;
    left: -20px;
    top: 0px;
    height: 327px;
    width: 400px;
    overflow: hidden;
  }
  .sale-tunnel-origin,
  .sale-tunnel-error {
    height: 327px;
    width: 400px;
    left: -400px;
  }
  .sale-tunnel-error__stats {
    margin-left: 20px;
  }
  .sale-tunnel-error__tab {
    padding: 8px 5px 8px 15px;
    height: calc(100% - 20px);
  }
  .sale-tunnel-error__cell {
    width: 230px;
    font-size: 12px;
  }
  .sale-tunnel-error__cell__numbers {
    width: 60px;
    height: 40px;
    font-size: 12px;
  }
  .sale-tunnel-error__group__title {
    font-size: 13px;
  }
  .sale-tunnel-error > .comparative-select__button {
    margin-left: 30px;
  }
  .sale-tunnel-error__close__button {
    width: 25px;
    height: 25px;
  }
  .sale-tunnel-error__close__icon {
    top: 2px;
    right: 2px;
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 495px) {
  .sale-tunnel-error__hide-zone {
    position: absolute;
    left: -10px;
    top: 0px;
    height: 327px;
    width: 330px;
    overflow: hidden;
  }
  .sale-tunnel-origin,
  .sale-tunnel-error {
    height: 327px;
    width: 330px;
    left: -330px;
  }
  .sale-tunnel-error__stats {
    margin-left: 10px;
  }
  .sale-tunnel-error__tab {
    padding: 8px 5px 8px 15px;
    height: calc(100% - 20px);
  }
  .sale-tunnel-error__cell {
    width: 180px;
    font-size: 12px;
  }
  .sale-tunnel-error__cell__numbers {
    width: 55px;
    height: 40px;
    font-size: 12px;
  }
  .sale-tunnel-error__group__title {
    font-size: 13px;
  }
  .sale-tunnel-error > .comparative-select__button {
    margin-left: 20px;
  }
}
