.toolbar__all-filters {
  position: relative;
  width: max-content;
  height: max-content;
}

.filters {
  width: max-content;
  height: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  border: 2px solid var(--color-shadow);
  background-color: var(--color-back);
  z-index: 2;
}

.filters__square {
  position: absolute;
  top: 50px;
  left: 20px;
  rotate: 45deg;
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-shadow);
  background-color: var(--color-back);
}

.filters__button {
  position: relative;
  z-index: 3;
  border: none;
  font-size: 16px;
  text-align: left;
  background-color: var(--color-back);
  border-radius: 0px;
  padding: 7px 18px 7px 15px;
}

.filters__button::after {
  content: ">";
  position: absolute;
  right: 4px;
}

.filters__button:last-child::after {
  content: "";
}

.filters__button:hover {
  background-color: var(--color-nav);
}

.toolbar__filters--open > .toolbar__filters__button {
  color: var(--color-front);
}

.filters__button--open::after {
  color: var(--color-valraiso);
}

.filters__all {
  position: absolute;
  top: 59px;
  left: 0px;
  display: flex;
}

.filters__choose {
  width: max-content;
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  border: 2px solid var(--color-shadow);
  background-color: var(--color-back);
  z-index: 2;
  overflow-y: auto;
  margin-left: -2px;
}

.filters__possibilities {
  position: relative;
  border: none;
  font-size: 16px;
  text-align: left;
  background-color: var(--color-back);
  padding: 7px 18px 7px 15px;
  z-index: 3;
}

.filters__possibilities:hover {
  background-color: var(--color-nav);
}

.filters__possibilities--open {
  color: var(--color-valraiso);
}

.filters__selected-filter {
  padding: 4px 8px;
  font-size: 16px;
  color: var(--color-front);
}

.filters__display {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

@media (max-width: 695px) {
  .filters__selected-filter {
    font-size: 9px;
    margin-right: 3px;
  }
  .filters__display {
    gap: 5px;
  }
  .filters__possibilities,
  .filters__button {
    font-size: 13px;
  }
}

@media (max-width: 495px) {
  .filters__display {
    gap: 0px;
  }
  .filters__possibilities,
  .filters__button {
    font-size: 11px;
  }
  .filters__button {
    padding: 7px 14px 7px 8px;
  }
  .filters__possibilities {
    padding: 7px 8px 7px 8px;
  }
}
