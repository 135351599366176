.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -65px;
}

.login__inner {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 26px;
  background-color: var(--color-ice-light);
  border-radius: 8px;
}

.login__header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__logo {
  width: 40px;
  height: 40px;
  fill: var(--color-valraiso);
}

.login__logo__name {
  width: 100px;
  height: 25px;
  fill: var(--color-front);
  margin-right: 8px;
}

.login__title {
  margin: 0;
  margin-bottom: -10px;
  text-align: center;
  font-weight: 400;
  color: var(--color-front);
}

.login__valraiso__logo {
  width: 140px;
}

.login--peekperformances {
  width: 200px;
}

@media (prefers-color-scheme: dark) {
  .login__inner {
    background-color: var(--color-dark-back);
  }
}
