.date-comparison {
  width: calc(var(--max-width) - 50px);
  min-height: 220px;
  /* box-shadow: 0 5px 15px var(--color-div-shadow); */
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.date-comparison__title {
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  color: var(--color-front);
}

.date-comparison__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.date-comparison__top > div {
  flex: inherit !important;
}

.top-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.date-comparison__select-comparison {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 13px;
  color: var(--color-front);
  font-weight: bold;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.date-comparison__select-comparison__button {
  margin-top: 3px;
  width: 110px;
  height: 1.3rem;
  border: 1px solid var(--color-shadow);
  position: relative;
}

.date-comparison__select-comparison__button__icon {
  position: absolute;
  top: 3px;
  right: 2px;
  fill: var(--color-front);
  width: 15px;
  height: 15px;
}

.date-comparison__select-comparison__button:focus {
  outline: 1px solid var(--color-selected);
}

.date-comparison__select-comparison__button > span {
  position: absolute;
  left: 6px;
  top: 3px;
  text-align: left;
}

.date-comparison__select-comparison__options {
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 3.3rem;
  transition: height 0.2s ease;
  background-color: var(--color-back);
  box-shadow: 0 5px 15px var(--color-div-shadow);
  animation: cmpOpen 0.2s ease;
}

@keyframes cmpOpen {
  0% {
    height: 0rem;
  }
  50% {
    height: 1.5rem;
  }
  100% {
    height: 3.3rem;
  }
}

.date-comparison__select-comparison__option {
  width: 110px;
  height: 1.1rem;
  border: none;
  text-align: left;
  padding-left: 10px;
}

.date-comparison__select-comparison__option:hover {
  background-color: var(--color-nav);
}
