.comparative {
  box-shadow: 0 5px 15px var(--color-div-shadow);
  margin-bottom: 50px;
}

.comparative__detail {
  width: 100%;
  align-items: center;
  padding: 0 10px 10px 10px;
}

.comparative__content {
  width: calc(var(--max-width) - 50px);
  box-shadow: 0 5px 15px var(--color-div-shadow);
  position: relative;
  border-radius: 4px;
}

.comparative-line__loader {
  width: 27%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.comparative-line__loader__button {
  position: absolute;
  top: -25px;
  overflow: hidden;
  border: none;
  display: flex;
  align-items: center;
  background-color: var(--color-back);
  box-shadow: 0 2px 5px rgba(200, 200, 200, 45%);
  padding-left: 20px;
}

.comparative-line__loader__back {
  position: absolute;
  left: 0px;
  height: 100%;
  width: 20px;
  width: max-content;
  background-color: var(--color-nav);
  display: flex;
  align-items: center;
  justify-content: center;
}

.comparative-line__loader__icon {
  width: 15px;
  height: 15px;
  fill: var(--color-front);
}

.comparative-line__loader__back--loading {
  animation: loading-back 1s ease forwards;
}
.comparative-line__loader__icon--loading {
  animation: hourglass-loading-rotate 1s ease infinite;
}

.comparative-line__loader__back--loading-end {
  width: 100%;
}

@keyframes loading-back {
  0% {
    width: 20px;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 945px) {
  .comparative-line__loader__button {
    top: -66px;
    left: -35px;
  }
}

@media (max-width: 695px) {
  .comparative-line__loader__button {
    font-size: 10px;
    top: 8px;
    left: inherit;
    right: 120px;
  }
  .comparative-line__loader__icon {
    width: 12px;
    height: 12px;
  }
  .comparative__download {
    position: relative;
    top: -8px;
  }
  .comparative-line__loader {
    position: inherit;
  }
}

@media (max-width: 495px) {
  .comparative-line__loader__button {
    top: 30px;
    right: 20px;
  }
}
