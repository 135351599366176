.navigation {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 65px;
  background-color: var(--color-nav);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
}

.navigation__title-zone {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.navigation__title {
  display: flex;
  width: calc(var(--max-width) - 50px);
  font-size: 30px;
  font-weight: 700;
  padding-top: 25px;
}

.navigation > div > label {
  margin-left: 30px;
}

.navigation__valraiso__logo {
  width: 180px;
}

.navigation__valraiso__logo--without-name {
  width: 0;
}

.navigation__deconnexion {
  position: relative;
  z-index: 5;
  font-size: 16px;
  color: var(--color-front);
  background-color: var(--color-nav) !important;
}

.navigation__title--menu {
  font-size: 45px;
  font-weight: 600;
  z-index: 5;
  position: relative;
  display: flex;
  align-items: center;
}

.navigation__valraiso__menu {
  background-color: var(--color-back);
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 67px;
  height: 65px;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;
}

.navigation__valraiso__menu__box {
  position: absolute;
  top: 0;
  left: 0;
  width: 67px;
  height: 65px;
  box-shadow: 0 5px 15px rgba(200, 200, 200, 65%);
  z-index: 0;
}

.navigation__valraiso__menu > svg {
  fill: var(--color-front);
  padding: 4px;
  width: 32px;
  height: 32px;
  margin-left: 0px;
  pointer-events: none;
}

.navigation__user {
  display: flex;
  gap: 6px;
  align-items: center;
}

.navigation__menu__zone {
  position: relative;
  display: flex;
  z-index: 5;
}

@media (max-width: 945px) {
  .navigation__deconnexion {
    margin-right: 20px;
    font-size: 14px !important;
    height: 30px !important;
    min-height: 30px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  .navigation__title {
    font-size: 24px;
  }
  .navigation__valraiso__logo {
    width: 140px;
  }
}

@media (max-width: 695px) {
  .navigation > div > label {
    margin-left: 5px;
  }
  .navigation__deconnexion {
    margin-right: 5px;
    font-size: 12px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .navigation__deconnexion > span {
    margin-right: 3px !important;
  }
  .navigation__title {
    font-size: 18px;
  }
  .navigation__valraiso__logo {
    width: 120px;
  }
  .navigation__user {
    gap: 2px;
  }
  .navigation__valraiso__menu__box {
    box-shadow: 0 0 0;
  }
  .navigation__valraiso__menu--open {
    box-shadow: 0 5px 15px rgba(200, 200, 200, 65%);
  }
}

@media (max-width: 600px) {
  .navigation__valraiso__logo {
    width: 0;
    height: 0;
  }
  .navigation__valraiso__logo--without-name {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 495px) {
  .navigation > div > label {
    margin-left: 2px;
  }
  .navigation__deconnexion {
    margin-right: 2px;
    font-size: 12px !important;
  }
  .navigation__deconnexion > span {
    margin-right: 2px !important;
  }
  .navigation__deconnexion > span > svg {
    width: 19px !important;
    height: 19px !important;
  }
  .navigation__user {
    gap: 0px;
  }
}
