.pagination {
  position: absolute;
  height: 100%;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pagination__button {
  border: none;
  background: none;
  padding: 0;
  font-size: 15px;
  font-weight: 550;
  color: var(--color-valraiso);
  background-color: var(--color-valraiso-fade);
  padding: 2px;
}

.pagination__button:hover {
  cursor: pointer;
}
