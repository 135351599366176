.seo-ads-impact__filters {
  background-color: var(--color-darker-nav);
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-front-light);
}

.seo-ads-impact__filters:hover {
  cursor: pointer;
}

.seo-ads-impact__filters__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.seo-ads-impact__filters__buttons {
  display: flex;
  z-index: 2;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  align-items: flex-start;
  gap: 4px;
  background-color: var(--color-back);
  border: 2px solid var(--color-darker-nav);
  border-radius: 4px;
  padding: 6px 8px 6px 0;
}
