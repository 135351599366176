.feedbackContainer {
  position: fixed;
  right: calc(-1 * var(--drawer-width));
  top: 70%;
  width: max-content;
  z-index: 10;
  transition: width var(--transition-duration) var(--transition-transition);
}

.containerOpened {
  right: 0;
  transition: right var(--transition-duration) var(--transition-transition);
}

.feedbackButtonContainer {
  position: absolute;
  left: 2px;
  top: 0;
  transform: rotate(-90deg) translate(-100%, -100%);
  transform-origin: top left;
  padding: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: var(--color-back);
  font-weight: 700;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 112px;
  gap: 5px;
  color: var(--color-front);
  border: 2px solid var(--color-darker-nav);
  transition: color,
  background-color var(--transition-duration) var(--transition-transition);
}

.sent {
  background-color: var(--color-green);
  color: var(--color-pos);
  cursor: unset;
}

.buttonIcon {
  transform: rotate(90deg);
}

.feedback {
  background-color: var(--color-back);
  border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
  width: var(--drawer-width);
  gap: 8px;
  border: 2px solid var(--color-darker-nav);
}

.open {
  width: var(--drawer-width);
}

.sentiments {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.sentiment {
  background-color: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-basis: calc(var(--drawer-width) / 5);
  width: calc(var(--drawer-width) / 5);
  color: var(--color-icon);
  margin-bottom: 4px;
  transition: color var(--transition-duration) var(--transition-transition);;
}

.sentiment > span {
  font-size: small;
  line-height: 16px;
  font-weight: 400;
  transition: all var(--transition-duration) var(--transition-transition);
}

.sentiment:hover > span,
.selected > span {
  font-size: medium;
  line-height: 20px;
  font-weight: 700;
}

.sentiment > svg {
  width: 30px;
  height: 30px;
  fill: var(--color-front);
  transition: all var(--transition-duration) var(--transition-transition);
}

.sentiment:hover > svg,
.selected > svg {
  width: 50px;
  height: 50px;
}

.green:hover > span,
.green:hover > svg,
.selected.green > span,
.selected.green > svg {
  fill: green;
  color: green;
}

.red:hover > span,
.red:hover > svg,
.selected.red > span,
.selected.red > svg {
  fill: red;
  color: red;
}

.yellow:hover > span,
.yellow:hover > svg,
.selected.yellow > span,
.selected.yellow > svg {
  fill: orange;
  color: orange;
}

.blue:hover > span,
.blue:hover > svg,
.selected.blue > span,
.selected.blue > svg {
  fill: blue;
  color: blue;
}

.orange:hover > span,
.orange:hover > svg,
.selected.orange > span,
.selected.orange > svg {
  fill: #ff842d;
  color: #ff842d;
}


.title {
  color: var(--color-front);
  font-size: medium;
  font-weight: 700;
  line-height: 24px;
}

.subtitle {
  color: var(--color-icon);
  font-size: small;
}

.commentSend {
  background-color: var(--color-back);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
}

.commentSend > textarea {
  flex-grow: 1;
  width: 100%;
  height: 50px;
  color: var(--color-front);
  background-color: var(--color-back);
  font-size: small;
  resize: none;
  border: 2px solid var(--color-darker-nav);
  border-radius: 4px;
  padding: 3px;
}

.submit {
  border-radius: 4px;
  color: var(--color-valraiso);
  font-weight: 700;
  border: none;
  height: max-content;
}

.submit:hover {
  background-color: var(--color-darker-nav);
}
.submit:hover {
  cursor: pointer;
}
