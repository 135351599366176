.array {
  border: 1px solid var(--color-ice);
  text-align: left;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
}

.array__line {
  height: 60px;
  margin-left: 5px;
  position: relative;
}

.array__top-line {
  text-align: center;
  background-color: var(--color-ice-light);
  height: 70px;
  position: relative;
}

.array__top-line > th {
  padding: 0;
  border-bottom: 1px solid var(--color-ice);
  border-right: 1px solid var(--color-ice);
}

.array__line__case {
  border-bottom: 1px solid var(--color-ice);
  border-right: 1px solid var(--color-ice);
  padding-left: 10px;
}

.array__line > td:last-of-type,
.array__top-line > th:last-of-type {
  border-right: none;
}

.array__line:last-of-type > th,
.array__line:last-of-type > td {
  border-bottom: none;
}

.array__top-line:last-of-type > th {
  border-bottom: 1px solid var(--color-ice);
}

.array td {
  font-size: 14px;
}
.array th {
  font-size: 15px;
}

.array__settings__button {
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
}

.array__settings__button > svg {
  width: 20px;
  height: 20px;
  fill: var(--color-front);
}

.array__settings__button:hover {
  background-color: var(--color-ice);
}

.array__line__edition__top {
  content: "";
  position: absolute;
  width: calc(100% + 10px);
  left: -10px;
  top: 0px;
  height: 10px;
  border: none;
  padding: 0;
  border-bottom: 2px solid var(--color-ice);
  background: none;
  z-index: 1;
  display: none;
  border-radius: 0;
}
.array__line__edition__top > svg {
  position: absolute;
  left: 20px;
  top: -7px;
  width: 24px;
  height: 24px;
  fill: var(--color-ice);
}

.array__line__edition__bottom {
  content: "";
  position: absolute;
  width: calc(100% + 10px);
  left: -10px;
  bottom: 0px;
  height: 10px;
  border: none;
  padding: 0;
  border-top: 2px solid var(--color-ice);
  background: none;
  z-index: 1;
  display: none;
  border-radius: 0;
}
.array__line__edition__bottom > svg {
  position: absolute;
  left: 20px;
  bottom: -7px;
  width: 24px;
  height: 24px;
  fill: var(--color-ice);
}

.array__line--edition {
  background-color: var(--color-nav);
}

.array__settings__remove__button {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  position: absolute;
  right: 5px;
  width: 25px;
  height: 25px;
  top: 18px;
  background: none;
}
.array__settings__remove__button > svg {
  width: 20px;
  height: 20px;
  fill: var(--color-ice);
}

.array__line--edition:hover .array__settings__remove__button {
  display: flex;
}
.array__line--edition:hover > th > .array__line__edition__bottom,
.array__line--edition:hover > th > .array__line__edition__top {
  display: inherit;
}

.array__line__edition__top:hover > svg,
.array__line__edition__bottom:hover > svg {
  fill: var(--color-front);
}

.array__settings__remove__button:hover > svg {
  fill: var(--color-front);
}
.array__settings__remove__button:hover {
  background-color: var(--color-ice);
}

.array__line__case__see-more {
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 13px;
  padding: 5px;
  font-size: 10px;
  background-color: var(--color-back);
  text-decoration: underline;
  fill: var(--color-front-light);
}

.array__line__case__see-more:hover {
  cursor: pointer;
  background-color: var(--color-ice-light);
}

.array__line--edition .array__line__case__see-more {
  display: none;
}
