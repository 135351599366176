.chosen-filter {
  display: flex;
  padding: 4px;
  border-radius: 5px;
  box-shadow: 0 0 0 1px var(--color-shadow);
  background-color: var(--color-back);
  align-items: center;
  color: var(--color-front);
  font-size: 15px;
  justify-content: center;
}

.chosen-filter__icon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  fill: var(--color-front);
}

.chosen-filter__button {
  border: none;
  background-color: none;
  width: 20px;
  height: 20px;
  position: relative;
}

.chosen-filter__button:hover > .chosen-filter__icon {
  fill: var(--color-selected);
  cursor: pointer;
}
