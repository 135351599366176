.seo {
  margin-top: 20px;
  width: 90%;
  max-width: 1480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;
  box-shadow: 0 5px 15px var(--color-div-shadow);
}

.seo--loading {
  margin-bottom: 500px;
}

.seo__backdrop {
  position: absolute;
  margin-top: 20px;
  width: 90%;
  max-width: 1480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 60px;
  box-shadow: 0 5px 15px var(--color-div-shadow);
}
.seo__backdrop > div {
  opacity: 0%;
}

.seo__top {
  display: flex;
  padding: 20px 20px 30px 20px;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  flex-wrap: wrap;
}

.seo__top__column {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
  gap: 20px;
}

.seo__top__right {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.seo__top__right__filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.seo__top__select {
  width: 200px;
}

.seo__syncdate {
  font-size: 24px;
  font-weight: 700;
  text-align: end;
  margin-right: 20px;
  margin-top: 10px;
}

.seo__historic {
  background-color: var(--color-ice-light);
}

@media (max-width: 695px) {
  .seo__top {
    flex-direction: column;
    font-size: 20px;
    gap: 15px;
    text-align: left;
  }
  .seo__top__right {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .seo__top__right__filters {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
}
