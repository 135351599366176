.settings-alerts__array {
  display: table;
  text-align: left;
  width: 80%;
  font-size: 15px;
}

.settings-alerts__array__line {
  display: flex;
  height: max-content;
  width: 100%;
}

.settings-alerts__array__line__keyword,
.settings-alerts__array__line__others,
.settings-alerts__array__line__filter {
  display: flex;
  width: 30%;
  align-items: center;
  padding: 18px 0 18px 10px;
  vertical-align: middle;
  border-bottom: 1px solid var(--color-darker-nav);
  position: relative;
}

.settings-alerts__array__line__keyword {
  width: 40%;
}

.settings-alerts__array__lines {
  max-height: 500px;
  overflow-y: auto;
}

.settings-alerts__array__line__device {
  width: 60%;
}

.settings-alerts__array__line__actions {
  width: 40%;
}

.settings-alerts__array__line__add {
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  width: 100%;
  padding: 18px 0 18px 10px;
  font-size: 16px;
  color: var(--color-valraiso);
}

.settings-alerts__array__line__add > svg {
  fill: var(--color-valraiso);
  width: 25px;
  height: 25px;
}

.settings-alerts__array__line__add:hover {
  cursor: pointer;
  background-color: var(--color-nav);
}

.settings-alerts__array__title {
  font-weight: 550;
  font-size: 17px;
}

.settings-alerts__array__line__action__wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  gap: 15px;
}

.settings-alerts__array__line__action {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
}

.settings-alerts__array__line__action > svg {
  width: 24px;
  height: 24px;
  fill: var(--color-front-light);
}

.settings-alerts__array__line__action:hover {
  background-color: var(--color-nav);
  cursor: pointer;
}

.settings-alerts__array__line__action--off {
  opacity: 0%;
}

.settings-alerts__array__line__action--off:hover {
  background-color: var(--color-back);
  cursor: auto;
}

.settings-alerts__array__line__update {
  width: max-content;
  height: 30px;
  position: absolute;
  top: 18px;
}

@media (max-width: 1350px) {
  .settings-alerts__array {
    width: 100%;
  }
}

@media (max-width: 945px) {
  .settings-alerts__array__line {
    flex-direction: column;
    width: 100%;
    padding: 8px 0;
  }
  .settings-alerts__array__line__keyword,
  .settings-alerts__array__line__filter,
  .settings-alerts__array__line__others {
    padding: 3px 0 3px 10px;
    width: 100%;
  }
  .settings-alerts__array__line__others {
    justify-content: space-between;
  }
  .settings-alerts__array__line__keyword,
  .settings-alerts__array__line__filter {
    border-bottom: none;
  }
  .settings-alerts__array__line__device,
  .settings-alerts__array__line__actions {
    width: max-content;
  }

  .settings-alerts__array__title {
    flex-direction: row;
    padding: 0;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-darker-nav);
  }
  .settings-alerts__array__title > .settings-alerts__array__line__keyword,
  .settings-alerts__array__title > .settings-alerts__array__line__filter,
  .settings-alerts__array__title > .settings-alerts__array__line__others {
    width: max-content;
    border-bottom: none;
  }
  .settings-alerts__array__title > .settings-alerts__array__line__others {
    padding-right: 10px;
  }
  .settings-alerts__array__title .settings-alerts__array__line__actions {
    display: none;
  }
}

@media (max-width: 600px) {
  .settings-alerts__array__line--edit select,
  .settings-alerts__array {
    font-size: 13px;
  }
  .settings-alerts__array__line__action {
    width: 24px;
    height: 24px;
    z-index: 1;
  }
  .settings-alerts__array__line__action > svg {
    width: 18px;
    height: 18px;
  }
  .settings-alerts__array__title {
    font-size: 15px;
  }
}

@media (max-width: 495px) {
  .settings-alerts__array__line--edit select,
  .settings-alerts__array {
    font-size: 12px;
  }
}
