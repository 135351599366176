.seo-ranks__badges {
  width: calc(35px * 4 + 5 * 10px);
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.seo-ranks__badge {
  display: flex;
  align-items: center;
  position: relative;
}

.seo-ranks__badges__line {
  position: relative;
  border-right: 1px solid rgba(167, 167, 167, 0.161);
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-right: 8px;
}

.seo-ranks__badges__line:first-of-type {
  margin-top: 49px;
  height: calc(var(--seo-ranks-height) + 1px);
  border-top: 1px solid rgba(167, 167, 167, 0.161);
}

.seo-ranks__badges__button {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}

.seo-ranks__badges__button__paa {
  background-color: rgb(239, 230, 174);
}
.seo-ranks__badges__button__gmaps {
  background-color: rgb(196, 241, 200);
}
.seo-ranks__badges__button__gmb {
  background-color: rgb(162, 188, 233);
}
.seo-ranks__badges__button__ads {
  background-color: rgb(240, 179, 179);
}

.seo-ranks__badges__paa__icon,
.seo-ranks__badges__gmb__icon,
.seo-ranks__badges__gmap__icon,
.seo-ranks__badges__ads__icon {
  width: 22px;
  height: 22px;
  position: absolute;
}

.seo-ranks__badges__ads__icon {
  fill: rgb(175, 10, 10);
}

.seo-ranks__badges__gmap__icon {
  fill: rgb(12, 126, 23);
}

.seo-ranks__badges__gmb__icon {
  fill: rgb(13, 45, 100);
}

.seo-ranks__badges__paa__icon {
  fill: rgb(110, 98, 20);
}

.seo-ranks__badges--off {
  background-color: rgba(242, 242, 242, 0.428);
}
.seo-ranks__badges--off > svg {
  fill: rgba(190, 190, 190, 0.392);
}

.seo-ranks__badges__warning {
  position: absolute !important;
  top: 14px;
  left: -8px;
  z-index: 4;
}

.seo-ranks__badges__warning svg {
  width: 24px;
  height: 24px;
  fill: var(--color-neg);
}

@media (max-width: 695px) {
  .seo-ranks__badges {
    display: none;
  }
  .seo-ranks__badges--small {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
  }
  .seo-ranks__badges__line {
    border-right: none;
    padding: 0px;
    gap: 0;
    justify-content: space-between;
  }

  .seo-ranks__badges__line:first-of-type {
    margin: 0;
    height: max-content;
    border: none;
  }
  .seo-ranks__badges__button {
    width: 22px;
    height: 22px;
  }
  .seo-ranks__badges__paa__icon,
  .seo-ranks__badges__gmb__icon,
  .seo-ranks__badges__gmap__icon,
  .seo-ranks__badges__ads__icon {
    width: 14px;
    height: 14px;
    position: absolute;
  }
  .seo-ranks__badges__warning {
    top: 3px;
    left: -14px;
    z-index: 4;
  }
  .seo-ranks__badges__warning svg {
    width: 14px;
    height: 14px;
  }
}
