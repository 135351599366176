.filter {
  position: relative;
  width: max-content;
  height: max-content;
}

.filter__button {
  display: flex;
  padding: 4px;
  height: 33px;
  border-radius: 5px;
  box-shadow: 0 0 0 2px var(--color-darker-nav);
  background-color: var(--color-darker-nav);
  align-items: center;
  justify-content: center;
}

.filter__button__icon {
  width: 20px;
  height: 20px;
  fill: var(--color-icon);
}

.filter__button__button {
  border: none;
  max-width: 168px;
  max-height: 33px;
  background-color: initial;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-front-light);
  overflow-x: auto;
  overflow-y: auto;
}

.filter__button:hover {
  box-shadow: 0 0 0 2px var(--color-selected);
  cursor: pointer;
}

.filter__button__button:hover {
  cursor: pointer;
}

.filter__button__close {
  width: 15px;
  height: 15px;
  position: relative;
  border: none;
  background-color: inherit;
}

.filter__button__close__icon {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 1px;
  right: 0px;
  fill: var(--color-icon);
}

.filter__button__close:hover > .filter__button__close__icon {
  fill: var(--color-selected);
  cursor: pointer;
}

.filter-scroll {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow: auto;
}

@media (max-width: 695px) {
  .filter__button__button {
    font-size: 10px;
  }
}

@media (max-width: 495px) {
  .filter__button {
    padding: 2px;
    height: 20px;
  }
  .filter__button__icon {
    width: 14px;
    height: 14px;
  }
  .filter__button__button {
    max-width: 135px;
    max-height: 20px;
    font-size: 10px;
  }
  .filter__button__close__icon {
    width: 11px;
    height: 11px;
    top: 2px;
  }
}
