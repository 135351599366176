.toolbar__period__zone {
  position: relative;
}

.toolbar__back {
  position: sticky;
  top: 55px;
  background-color: var(--color-back-filter);
  backdrop-filter: blur(3px);
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  width: calc(var(--max-width) - 50px);
  align-items: top;
  padding: 10px 0;
  padding-top: 30px;
  gap: 4px;
}

.toolbar__filters,
.toolbar__period {
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 4px;
  padding-left: 5px;
  box-shadow: 0 0 0 2px var(--color-shadow);
  background-color: var(--color-back);
  box-shadow: 0 0 0 2px var(--color-darker-nav);
  background-color: var(--color-darker-nav);
}
.toolbar__filters:hover,
.toolbar__period:hover {
  box-shadow: 0 0 0 2px var(--color-selected);
  cursor: pointer;
}

.toolbar__period {
  width: 220px;
}

.toolbar__filters__button:hover {
  cursor: pointer;
}

.toolbar__filters__button {
  border: none;
  background-color: initial;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-front-light);
}

.toolbar__filters__button__icon {
  fill: var(--color-icon);
  width: 20px;
}

.toolbar__filters--open {
  box-shadow: 0 0 0 2px var(--color-selected);
}

@media (max-width: 695px) {
  .toolbar__period {
    width: 120px;
  }
  .toolbar__filters__button {
    font-size: 10px;
  }
}

@media (max-width: 495px) {
  .toolbar__period {
    height: 20px;
  }
  .toolbar__filters__button__icon {
    width: 14px;
  }
}
