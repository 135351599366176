.slider {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  margin-top: 20px;
}

.slider__zone {
  height: 20px;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.sale-tunnel-slider,
.sale-tunnel-slider-2 {
  max-width: 600px;
  margin: auto;
}

.sale-tunnel-slider {
  position: absolute;
  left: -60px;
}
.sale-tunnel-slider-2 {
  display: none;
}

.sale-tunnel-slider__customSlider-track,
.sale-tunnel-slider-2__customSlider-track {
  top: 8px;
  height: 6px;
  border-radius: 6px;
  border: none;
  outline: none;
  background: var(--color-primary);
  box-shadow: 0 1px 2px 0px gray;
}

.sale-tunnel-slider__customSlider-track-0,
.sale-tunnel-slider-2__customSlider-track-0 {
  background: var(--color-ice-light);
}

.sale-tunnel-slider__customSlider-track-2,
.sale-tunnel-slider-2__customSlider-track-2 {
  background: var(--color-ice-light);
}

.sale-tunnel-slider__thumb,
.sale-tunnel-slider-2__thumb {
  position: relative;
  width: 22px;
  height: 22px;
  background-color: var(--color-ice-light);
  border: 2px solid var(--color-valraiso);
  box-shadow: 0 1px 2px 0px gray;
  border-radius: 50%;
  z-index: 1 !important;
}

.sale-tunnel-slider__thumb-0,
.sale-tunnel-slider-2__thumb-1 {
  border: 2px solid var(--color-valraiso-secondary);
}


.sale-tunnel-slider__customSlider-track--open + .sale-tunnel-slider__thumb,
.sale-tunnel-slider-2__customSlider-track--open + .sale-tunnel-slider-2__thumb {
  display: none;
}

.sale-tunnel-slider__thumb:focus,
.sale-tunnel-slider-2__thumb:focus {
  outline: none;
}

.sale-tunnel-slider__thumb:active,
.sale-tunnel-slider-2__thumb:active {
  box-shadow: 0 0 0 12px var(--color-primary-fade);
}

.slider__data {
  font-size: 17px;
  text-align: center;
  font-weight: 550;
  display: flex;
  gap: 10px;
}

.slider__data__center {
  margin-left: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 10px;
  gap: 10px;
}

.slider__data__percentage {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 945px) {
  .slider {
    height: 90px;
  }
  .sale-tunnel-slider {
    max-width: 410px;
    left: -45px;
  }
  .slider__data {
    font-size: 13px;
  }
  .slider__data__center {
    margin-left: 45px;
    gap: 8px;
  }
  .slider__data__percentage {
    font-size: 18px;
  }
}

@media (max-width: 695px) {
  .slider__zone__vertical {
    height: 430px;
    width: 20px;
    position: relative;
    top: -30px;
    margin-bottom: 20px;
  }
  .slider {
    height: max-content;
    margin-top: 0px;
    justify-content: center;
    align-items: center;
  }
  .slider__data {
    font-size: 10px;
  }
  .slider__data__center {
    margin-left: 0;
    padding: 0;
    gap: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    margin-top: -20px;
    align-items: center;
  }
  .slider__data__percentage {
    font-size: 15px;
  }
  .sale-tunnel-slider-2 {
    height: 400px;
    width: 25px;
    margin: auto;
    top: 45px;
    overflow-y: hidden;
  }
  .sale-tunnel-slider-2__customSlider-track {
    height: 400px;
    width: 4px;
    top: 8px;
    left: 8px;
    border-radius: 6px;
    border: none;
    outline: none;
    background: var(--color-primary);
    box-shadow: 0 1px 2px 0px gray;
  }
  .sale-tunnel-slider-2__customSlider-track-0 {
    height: 330px;
    width: 4px;
    top: 8px;
    left: 8px;
    border-radius: 6px;
    border: none;
    outline: none;
    box-shadow: 0 1px 2px 2px var(--color-back);
    background: var(--color-shadow);
  }
  .sale-tunnel-slider-2__customSlider-track-2 {
    position: absolute;
    height: 330px;
    width: 4px;
    top: 8px;
    left: 8px;
    border-radius: 6px;
    border: none;
    outline: none;
    background: var(--color-shadow);
    box-shadow: 0 1px 2px 2px var(--color-back);
  }
  .sale-tunnel-slider-2__thumb:active {
    box-shadow: none;
  }
  .sale-tunnel-slider-2 {
    display: flex;
  }
  .sale-tunnel-slider {
    display: none;
  }
  .sale-tunnel-slider__thumb,
  .sale-tunnel-slider-2__thumb {
    width: 18px;
    height: 18px;
    left: 1px;
  }
}

@media (max-width: 495px) {
  .slider__zone__vertical {
    height: 420px;
  }
  .slider__data {
    font-size: 8px;
  }
  .slider__data__center {
    gap: 0;
    right: 0px;
    margin-top: -50px;
    margin-bottom: 0px;
  }
  .slider__data__percentage {
    font-size: 11px;
  }
  .sale-tunnel-slider-2 {
    top: 25px;
    height: 380px;
  }
}
