.seo-ranks__ranking__zone {
  flex: 1 0 auto;
  width: 0px;
  padding-right: 20px;
  position: relative;
}

.seo-ranks__ranking {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding-top: 300px;
  margin-top: -300px;
}

.seo-ranks__ranking__line {
  display: flex;
  align-items: center;
  gap: 15px;
  width: max-content;
  position: relative;
  padding: 0 4px;
}

.seo-ranks__ranking__button {
  border: none;
  background-color: var(--color-back);
  padding: 0;
  margin: 0;
  height: 25px;
  min-height: 25px;
  min-width: 75px;
  width: 75px;
  overflow: hidden;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid var(--color-back);
  box-shadow: 0 0 1px 1px rgb(201, 201, 201);
}

.seo-ranks__ranking__numbers {
  min-width: 75px;
  width: 75px;
  overflow: hidden;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-front);
}

.seo-ranks__ranking__popup {
  text-align: left;
  position: absolute;
  z-index: 5;
  max-width: 500px;
  max-height: 400px;
  width: max-content;
  height: max-content;
  overflow: auto;
}

.seo-ranks__ranking__previous,
.seo-ranks__ranking__next {
  width: 20px;
  height: 20px;
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 15px;
  z-index: 1;
}

.seo-ranks__ranking__previous {
  left: -20px;
}
.seo-ranks__ranking__next {
  right: 0px;
}

.seo-ranks__ranking__next > svg,
.seo-ranks__ranking__previous > svg {
  width: 20px;
  height: 20px;
  fill: var(--color-front);
}

.seo-ranks__ranking__next--max > svg,
.seo-ranks__ranking__previous--max > svg {
  fill: var(--color-font);
}

.seo-ranks__ranking__button--alpedhuez {
  box-shadow: 0 0 1px 1px #25388e;
}

.seo-ranks__ranking__button--esf {
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  background-color: var(--color-esf);
  color: white;
  box-shadow: 0 0 1px 1px var(--color-esf);
}

.seo-ranks__ranking__button--esf::after {
  content: attr(precision);
  position: relative;
  font-size: 13px;
  left: 2px;
  top: 1px;
}

.seo-ranks__ranking__button__zone {
  min-width: 75px;
  position: relative;
}

@media (max-width: 695px) {
  .seo-ranks__ranking__button__zone {
    min-width: 58px;
  }
  .seo-ranks__ranking__button {
    min-width: 58px;
    width: 58px;
    height: 21px;
    min-height: 21px;
    font-size: 11px;
  }
  .seo-ranks__ranking__button--esf::after {
    font-size: 9px;
  }
  .seo-ranks__ranking__line {
    gap: 10px;
  }
  .seo-ranks__ranking__numbers {
    min-width: 58px;
    width: 58px;
  }
  .seo-ranks__top__line > .seo-ranks__ranking__numbers {
    font-size: 15px;
  }
}
