.custom-period {
  position: absolute;
  width: 669px;
  height: 421px;
  top: -2px;
  right: 166px;
  background-color: var(--color-back);
  border-radius: 4px 0 4px 4px;
}

.date-range {
  width: 664px;
}

.custom-period__deletion,
.custom-period__validation {
  width: 100px;
  font-weight: bold;
  background-color: var(--color-selected);
  color: var(--color-nav);
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--color-shadow);
}
.custom-period__deletion:focus,
.custom-period__validation:focus {
  outline: 2px solid rgb(20, 101, 208);
  transition: all 0.1s ease;
}

.custom-period__deletion:hover,
.custom-period__validation:hover {
  cursor: pointer;
}

.custom-period__deletion {
  display: none;
}

.rdrCalendarWrapper {
  border: 2px solid var(--color-shadow);
}
.rdrDefinedRangesWrapper {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .rdrDateDisplayWrapper {
    background-color: var(--color-nav);
  }
  .rdrMonths,
  .rdrMonthAndYearWrapper,
  .rdrDateRangePickerWrapper,
  .rdrDateDisplayItem,
  .rdrDateInput > input,
  .rdrDateDisplayItemActive {
    color: var(--color-front);
    background-color: var(--color-back);
  }
  .rdrDateDisplayItem {
    box-shadow: none;
  }
  .rdrNextPrevButton {
    background-color: var(--color-nav);
  }
  .rdrNextPrevButton:hover {
    background-color: var(--color-icon);
  }
  .rdrNextButton > i {
    border-color: transparent transparent transparent var(--color-front);
  }
  .rdrPprevButton > i {
    border-color: transparent var(--color-front) transparent transparent;
  }
  .rdrYearPicker > select {
    color: var(--color-front);
    background: url("data:image/svg+xml;utf8,<svg width=%279px%27 height=%276px%27 viewBox=%270 0 9 6%27 version=%271.1%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27><g id=%27Artboard%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 transform=%27translate%28-636.000000, -171.000000%29%27 fill-opacity=%270.368716033%27><g id=%27input%27 transform=%27translate%28172.000000, 37.000000%29%27 fill=%27white%27 fill-rule=%27nonzero%27><g id=%27Group-9%27 transform=%27translate%28323.000000, 127.000000%29%27><path d=%27M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z%27 id=%27arrow%27></path></g></g></g></svg>")
      no-repeat;
    background-position: right 8px center;
  }
  .rdrMonthPicker > select {
    color: var(--color-front);
    background: url("data:image/svg+xml;utf8,<svg width=%279px%27 height=%276px%27 viewBox=%270 0 9 6%27 version=%271.1%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27><g id=%27Artboard%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 transform=%27translate%28-636.000000, -171.000000%29%27 fill-opacity=%270.368716033%27><g id=%27input%27 transform=%27translate%28172.000000, 37.000000%29%27 fill=%27white%27 fill-rule=%27nonzero%27><g id=%27Group-9%27 transform=%27translate%28323.000000, 127.000000%29%27><path d=%27M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z%27 id=%27arrow%27></path></g></g></g></svg>")
      no-repeat;
    background-position: right 8px center;
  }

  .rdrDay > span > span {
    color: var(--color-font);
  }
  .rdrDayPassive > .rdrDayNumber > span {
    color: var(--color-nav);
  }
  .rdrDayDisabled {
    background-color: var(--color-dark-back);
  }
  .rdrDayDisabled > .rdrDayNumber > span {
    color: black;
  }
  .rdrYearPicker > select::-ms-expand {
    display: none;
    background-color: red;
  }
}

@media (max-width: 945px) {
  .custom-period {
    width: max-content;
    height: max-content;
    top: -2px;
    right: 170px;
    border: none;
  }
  .date-range {
    width: 280px;
  }
  .custom-period__validation {
    width: 96px;
    font-size: 13px;
    padding: 6px;
  }

  .rdrCalendarWrapper {
    border: 2px solid var(--color-shadow);
  }
  .rdrDateDisplayWrapper {
    width: 280px;
    height: 40px;
  }
  .rdrDateDisplayWrapper > div > span > input {
    height: 1rem;
  }
  .rdrMonthAndYearWrapper {
    width: 280px;
    height: 40px;
  }
  .rdrMonthsHorizontal {
    width: 280px;
  }
  .rdrMonths {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 360px;
  }
  .rdrMonth {
    width: 260px;
    height: 180px;
    padding: 0 10px 10px 10px;
  }
  .rdrWeekDay {
    line-height: 1.3rem;
  }
  .rdrDay {
    line-height: 1.4rem;
    height: 1.4rem;
  }
  .rdrMonthName {
    padding: 1px;
  }
  .rdrDayNumber > span::after {
    top: 11px;
    height: 1px;
  }
}

@media (max-width: 695px) {
  .custom-period {
    width: max-content;
    height: max-content;
    top: -2px;
    right: 110px;
    border: none;
  }
}

@media (max-width: 495px) {
  .custom-period {
    position: fixed;
    top: -65px;
    left: 0;
    border: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 25%);
  }
  @media (prefers-color-scheme: dark) {
    .custom-period {
      background-color: rgba(0, 0, 0, 50%);
    }
  }
  .date-range {
    position: absolute;
    width: 280px;
    top: calc(50vh - 132px);
    left: calc(50vw - 143px);
    height: 300px;
  }
  .rdrMonths {
    height: 180px;
  }

  .rdrMonth:nth-child(2) {
    display: none;
  }
  .custom-period__validation {
    position: fixed;
    top: calc(50vh + 60px);
    left: calc(50vw + 25px);
    width: 96px;
    font-size: 13px;
    padding: 6px;
    z-index: 3;
  }
  .custom-period__deletion {
    display: inherit;
    position: fixed;
    top: calc(50vh + 60px);
    left: calc(50vw - 120px);
    width: 96px;
    font-size: 13px;
    padding: 6px;
    z-index: 3;
    padding: 6px 20px;
  }
  .rdrCalendarWrapper {
    background-color: var(--color-back);
  }
}
