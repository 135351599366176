.seo-ranks-stats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 20px;
}

.seo-ranks-stats__title__esf > .sale-tunnel-bloc__title {
  color: var(--color-esf);
  font-weight: bold;
}

.seo-ranks-stats__title__ot > .sale-tunnel-bloc__title {
  color: rgb(7, 106, 235);
  font-weight: bold;
}

.seo-ranks-stats__title__concurrent > .sale-tunnel-bloc__title {
  color: rgb(0, 189, 50);
  font-weight: bold;
}

.seo-ranks-stats__title__intermediaire > .sale-tunnel-bloc__title {
  color: rgb(218, 106, 0);
  color: rgb(174, 0, 218);
  font-weight: bold;
}

@media (max-width: 695px) {
  .seo-ranks-stats {
    padding: 0 20px;
  }
  .seo-ranks-stats div {
    padding: 5px 10px;
  }
}
