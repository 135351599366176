.seo-ranks {
  width: 100%;
  display: flex;
  padding: 15px;
  padding-bottom: 0;
}

.seo-ranks__line {
  display: flex;
  align-items: center;
  height: var(--seo-ranks-height);
  padding: 4px 0;
  border-bottom: 1px solid rgba(167, 167, 167, 0.161);
}

.seo-ranks__top__line {
  height: 50px;
  border-bottom: 1px solid rgba(167, 167, 167, 0.161);
  display: flex;
  align-items: center;
  font-weight: 550;
  font-size: 17px;
  position: relative;
  z-index: 1;
}

.seo-ranks__top__line > .seo-ranks__ranking__numbers {
  font-size: 17px;
}

.seo-ranks__line:last-of-type {
  margin-bottom: 35px;
}

@media (max-width: 695px) {
  .seo-ranks__top__line {
    font-size: 14px;
  }
  .seo-ranks {
    padding-right: 0;
  }
}
