.date-comparison-blocs {
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0px 40px 0px;
  justify-content: space-between;
}

@media (max-width: 945px) {
  .date-comparison-blocs {
    min-height: 322px;
    padding: 60px 70px 40px 70px;
    gap: 18px;
  }
}

@media (max-width: 695px) {
  .date-comparison-blocs {
    min-height: 334px;
    padding: 60px 10px 40px 10px;
    gap: 28px;
  }
}

@media (max-width: 495px) {
  .date-comparison-blocs {
    padding: 60px 0px 40px 0px;
    gap: 18px;
  }
}
