.period {
  position: absolute;
  top: 45px;
  right: 2px;
  background-color: var(--color-back);
  width: 170px;
  border: 2px solid var(--color-shadow);
  border-radius: 4px;
  display: flex;
}

.period__choices {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.period__choice {
  width: 166px;
  border: none;
  border-radius: 0;
  text-align: left;
  font-weight: bold;
  color: var(--color-front-light);
  padding: 10px;
}

.period__choice:hover {
  cursor: pointer;
}

.period__choice:first-child {
  border-radius: 2px 2px 0 0;
}

.period__choice:last-child {
  border-radius: 0 0 2px 2px;
}

.period__choice:hover {
  background-color: var(--color-nav);
}

.period__choice__separator {
  width: 166px;
  background-color: var(--color-shadow);
  border: none;
  height: 2px;
}

@media (max-width: 695px) {
  .period {
    width: 110px;
  }
  .period__choice {
    width: 96px;
    font-size: 9px;
    padding: 6px;
  }
  .period__choice__separator {
    width: 106px;
  }
}

@media (max-width: 495px) {
  .period {
    width: 100px;
    top: 28px;
    z-index: 2;
  }
  .period__choice {
    width: 96px;
    font-size: 9px;
    padding: 6px;
  }
  .period__choice__separator {
    width: 96px;
  }
}
