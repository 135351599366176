.comparative-select {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.comparative-select__button {
  border: none;
  font-size: 16px;
  border-radius: 0px;
  border-bottom: 2px solid var(--color-back);
}

.comparative-select__button--selected {
  border-bottom: 2px solid var(--color-valraiso);
}

.comparative-select__button:hover {
  cursor: pointer;
}

@media (max-width: 695px) {
  .comparative-select {
    gap: 6px;
  }
  .comparative-select__button {
    font-size: 14px;
    padding: 4px 4px;
  }
}
