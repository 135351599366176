@import "@valraiso-esf/esf-ui/es/theme.css";

:root {
  --max-width: 900px;
  --color-back: white;
  --color-front: rgb(48, 48, 48);
  --color-nav: rgb(250, 250, 250);
  --color-darker-nav: rgb(242, 242, 242);
  --color-back-filter: rgba(255, 255, 255, 85%);
  --color-primary-fade: #d16a6aa1;
  --color-shadow: #dce2e9;
  --color-font: #b6b9bc;
  --color-icon: #9d9fa1;
  --color-selected: rgb(61, 145, 255);
  --color-selected: #003fff;
  --color-div-shadow: rgba(169, 169, 178, 25%);
  --color-pos: rgb(30, 192, 16);
  --color-neg: rgb(212, 8, 8);
  --seo-ranks-height: 55px;
  --color-valraiso: #ff3c00;
  --color-valraiso-secondary: #003fff;
  --color-valraiso-fade: rgba(255, 60, 0, 10%);
  --color-primary: linear-gradient(45deg, #003fff, #ff3c00);
  --color-esf: #ca331c;
  --transition-duration: 320ms;
  --drawer-width: 320px;
  --transition-transition: cubic-bezier(0.4, 0.1, 0.2, 0.9);
}

@media (prefers-color-scheme: dark) {
  :root {
    --max-width: 900px;
    --color-back: rgb(48, 48, 48);
    --color-dark-back: rgb(40, 40, 40);
    --color-front: white;
    --color-nav: rgb(62, 62, 62);
    --color-darker-nav: rgb(62, 62, 62);
    --color-back-filter: rgb(48, 48, 48, 85%);
    --color-shadow: #dce2e9;
    --color-font: #b6b9bc;
    --color-icon: #9d9fa1;
    --color-selected: rgb(61, 145, 255);
    --color-div-shadow: rgba(169, 169, 178, 25%);
    --color-pos: rgb(30, 192, 16);
    --color-neg: rgb(212, 8, 8);
    --seo-ranks-height: 55px;
    --color-valraiso: #ff3c00;
    --color-valraiso-fade: rgba(255, 60, 0, 10%);
  }
}

@media (max-width: 945px) {
  :root {
    --max-width: 650px;
  }
}

@media (max-width: 695px) {
  :root {
    --max-width: 450px;
  }
}

@media (max-width: 495px) {
  :root {
    --max-width: 380px;
  }
}

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

#root {
  position: relative;
  top: 65px;
  width: 100vw;
  height: calc(100vh - 65px);
  color: var(--color-front);
  background-color: var(--color-back);
}

* {
  box-sizing: border-box;
}

button {
  border-radius: 4px;
  border: 1.5px solid var(--color-front);
  background-color: var(--color-back);
  padding: 4px 8px;
}

button,
div,
span {
  color: var(--color-front);
}

.button-valraiso-background {
  background-color: var(--color-valraiso) !important;
}

.loaderbar-valraiso-background > div {
  background-color: var(--color-valraiso) !important;
}

@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

@import "./components/common/array.css";
@import "./components/common/card.css";
@import "./components/common/pagination.css";
@import "./components/common/popup.css";
@import "./components/common/search-bar.css";
@import "./components/common/select-multiple/checkbox.css";
@import "./components/common/select-multiple/select-multiple.css";
@import "./components/common/settings/input.css";
@import "./components/common/settings/select.css";
@import "./components/common/filter/filter.css";
@import "./components/common/export.css";
@import "./components/common/switch-button.css";
@import "./components/common/popup-front.css";

@import "./components/app.css";
@import "./components/page-handler/navigation.css";
@import "./components/performances/toolbar/toolbar.css";
@import "./components/performances/statistics.css";
@import "./components/performances/toolbar/filters.css";
@import "./components/performances/toolbar/all-filters.css";
@import "./components/performances/toolbar/custom-period.css";
@import "./components/performances/toolbar/period.css";
@import "./components/performances/date-comparison/date-comparison.css";
@import "./components/performances/date-comparison/blocs.css";
@import "./components/performances/date-comparison/bloc.css";
@import "./components/performances/comparative/comparative.css";
@import "./components/performances/comparative/line.css";
@import "./components/performances/comparative/select.css";
@import "./components/performances/sale-tunnel/sale-tunnel.css";
@import "./components/performances/sale-tunnel/slider.css";
@import "./components/performances/sale-tunnel/error-detail.css";
@import "./components/performances/sale-tunnel/origin-detail.css";
@import "./components/performances/sale-tunnel/abort-detail.css";
@import "./components/performances/sale-tunnel/income.css";
@import "./components/performances/toolbar/chosen-filter.css";
@import "./components/performances/toolbar/admin-filter.css";
@import "./components/page-handler/menu.css";
@import "./components/page-handler/users-access.css";
@import "./components/page-handler/login.css";
@import "./components/seo-observatory/detail/index.css";
@import "./components/seo-observatory/detail/ranks/ranks.css";
@import "./components/seo-observatory/detail/ranks/badges.css";
@import "./components/seo-observatory/detail/ranks/keywords.css";
@import "./components/seo-observatory/detail/ranks/ranking.css";
@import "./components/seo-observatory/detail/ranks/popups.css";
@import "./components/seo-observatory/detail/ranks/stats.css";
@import "./components/seo-observatory/detail/ranks/glimpse.css";
@import "./components/page-handler/api-off.css";
@import "./components/seo-observatory/detail/select-date.css";
@import "./components/page-handler/top-scroller.css";
@import "./components/settings/index.css";
@import "./components/settings/alert-array.css";
@import "./components/study/index.css";
@import "./components/study/chart.css";
@import "./components/ads-impact/index.css";
@import "./components/ads-impact/chart.css";
@import "./components/ads-impact/filters.css";
