.sale-tunnel-abort {
  height: calc(100% - 45px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sale-tunnel-abort__content {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 40px;
}

.sale-tunnel-abort__bloc {
  width: max-content;
  height: max-content;
}

.sale-tunnel-abort__bloc--disable::after {
  background-color: var(--color-back-filter);
  filter: blur(3px);
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 6px;
}

.sale-tunnel-abort__message {
  padding: 10px;
  font-size: 13px;
}

@media (max-width: 945px) {
  .sale-tunnel-abort__message {
    font-size: 11px;
  }
}

@media (max-width: 695px) {
  .sale-tunnel-abort__message {
    font-size: 9px;
  }
}
