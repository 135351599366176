.seo-ranks__keywords {
  display: flex;
  flex-direction: column;
}

.seo-ranks__keywords__line {
  justify-content: flex-start;
  font-size: 15px;
  padding: 0 10px;
  border-right: 1px solid rgba(167, 167, 167, 0.161);
  position: relative;
}


.seo-ranks__keywords__line__volume {
  position: absolute;
  left: 0;
  top: 0px;
  background-color: rgb(125, 194, 240, 25%);
  background-color: rgba(25, 148, 230, 0.6);
  background-color: rgb(0, 90, 246, 0.7);
  height: 8px;
  border-bottom-right-radius: 8px;
}

.seo-ranks__station__line {
  font-weight: bold;
}

.seo-ranks__keywords__keyword {
  display: inline-block;
  text-decoration: none;
  background-color: initial;
  border: none;
  padding: 0;
  margin: 0;
  font-weight: 550;
  font-size: 15px;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
}

.seo-ranks__keywords__keyword::before,
.seo-ranks__keywords__keyword::after {
  content: '"';
  text-decoration: solid;
  font-weight: initial;
}

.seo-ranks__keywords__keyword:hover {
  cursor: pointer;
}

@media (max-width: 695px) {
  .seo-ranks__keywords__line {
    font-size: 10px;
    padding-right: 5px;
    padding-left: 0px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
  }
  .seo-ranks__keywords__keyword {
    font-size: 12px;
  }
}
