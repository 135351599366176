.settings {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
}

.settings-alerts {
  box-shadow: 0 5px 15px var(--color-div-shadow);
  width: 80%;
  max-width: 1480px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.settings-alerts__mail {
  width: max-content !important;
}

.settings-alerts__top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.settings-popup__back {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 65px;
  height: calc(100vh - 65px);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  backdrop-filter: blur(3px);
}

.settings-alerts__array__zone {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 20px 0px;
}

.settings-alerts__station__choice {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}

.settings-alerts__loaderbar {
  width: 200%;
}

.settings-alerts__searchbar {
  margin-left: 10px;
}

.settings-alerts__alert-type {
  width: 220px;
}

@media (max-width: 945px) {
  .settings-alerts__searchbar {
    margin: 0;
    position: absolute !important;
    top: -10px !important;
    left: 4px !important;
  }
}

@media (max-width: 495px) {
  .settings-alerts {
    width: 90%;
    padding: 10px;
  }
}
