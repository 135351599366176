.seo-study-chart__container {
  position: relative;
}

.seo-study-chart__chart {
  position: absolute;
  z-index: 3;
  top: calc(100% + 2px);
  right: 0;
}

.seo-ads-chart__tooltip {
  background-color: var(--color-back);
  padding: 5px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: center ;
  text-align: left;
  box-shadow: 0 2px 5px rgba(200, 200, 200, 45%);
  border-radius: 8px;
}

.seo-ads-chart__tooltip__top {
  display: flex;
  justify-content: space-between;
}

.seo-ads-chart__tooltip__top > span{
  margin-left: 10px;
  color: var(--color-valraiso);
}

.seo-ads-impact__chart {
  gap: 10px;
}
