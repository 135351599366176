.sale-tunnel-income {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 5px 15px rgb(200 200 200 / 65%); */
  overflow: hidden;
  border: none;
}

.sale-tunnel-income:hover {
  cursor: pointer;
}

.sale-tunnel-income__icon {
  width: 24px;
  height: 24px;
  fill: rgb(144, 126, 7);
  fill: var(--color-selected);
}

.sale-tunnel-income__zone {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.sale-tunnel-income__zone__inverse {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sale-tunnel-income__loader {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 550;
}

.sale-tunnel-income__loader:hover {
  text-decoration: underline;
}

.sale-tunnel-income__detail {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: -20px;
  font-size: 13px;
}

.sale-tunnel-income__detail__button {
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
}

.sale-tunnel-income__detail__button > svg {
  width: 20px;
  height: 20px;
  fill: var(--color-icon);
}

.sale-tunnel-income__detail__button:hover > svg {
  fill: var(--color-front-light);
}

.sale-tunnel-income__detail__popup__square {
  content: "";
  position: absolute;
  background-color: var(--color-back);
  border: 2px solid var(--color-ice);
  left: 12px;
  bottom: calc(120% - 8px);
  rotate: 45deg;
  z-index: 1;
  width: 20px;
  height: 20px;
}

.sale-tunnel-income__detail__popup {
  position: absolute;
  background-color: var(--color-back);
  border: 2px solid var(--color-ice);
  padding: 4px;
  left: 0px;
  bottom: 120%;
  z-index: 3;
  max-width: 100%;
}

.sale-tunnel-income--before-loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: -3px;
}

.sale-tunnel-income--before-loading__icon__zone {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-nav);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
}

.sale-tunnel-income--before-loading__icon {
  width: 25px;
  height: 25px;
  fill: var(--color-front);
}

.sale-tunnel-income--before-loading__span {
  width: 60px;
  font-size: 14px;
}

.sale-tunnel-income--loading__icon__zone {
  animation: hourglass-loading ease forwards 1s;
}

.sale-tunnel-income--loading__icon__zone
  + .sale-tunnel-income--before-loading__span {
  width: 0px;
  opacity: 0%;
  transition: all ease 1s;
}

.sale-tunnel-income--loading__icon__zone
  > .sale-tunnel-income--before-loading__icon {
  animation: hourglass-loading-rotate 1s ease 1s infinite;
}

@keyframes hourglass-loading {
  0% {
    left: 3px;
    transform: rotate(0deg);
  }
  100% {
    left: 3px;
    transform: rotate(360deg);
    width: 100px;
    height: 100px;
  }
}

@keyframes hourglass-loading-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sale-tunnel-income--end-loading > .sale-tunnel-income--before-loading__span {
  visibility: 0%;
}

.sale-tunnel-income--end-loading {
  overflow: hidden;
  background-color: var(--color-nav);
  border-radius: 50%;
  animation: hourglass-loading-disappear 1s ease forwards;
}

@keyframes hourglass-loading-disappear {
  0% {
    width: 100px;
    height: 100px;
  }
  100% {
    width: 0px;
    height: 0px;
  }
}

.tooltip {
  z-index: 2 !important;
}

@media (max-width: 945px) {
  .sale-tunnel-income__loader,
  .sale-tunnel-income {
    width: 75px;
    height: 75px;
    font-size: 12px;
  }
  .sale-tunnel-income__icon {
    width: 22px;
    height: 22px;
  }
  .sale-tunnel-income__zone {
    gap: 8px;
  }
  .sale-tunnel-income--before-loading__icon {
    width: 17px;
    height: 17px;
  }
  .sale-tunnel-income--before-loading__icon__zone {
    width: 24px;
    height: 24px;
  }
  .sale-tunnel-income--before-loading__span {
    font-size: 10px;
    width: 40px;
  }
  .sale-tunnel-income__detail {
    margin-bottom: -18px;
    font-size: 12px;
  }
  .sale-tunnel-income__detail__button > svg {
    width: 18px;
    height: 18px;
  }
  .sale-tunnel-income__detail__popup__square {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 695px) {
  .sale-tunnel-income__loader,
  .sale-tunnel-income {
    width: 58px;
    height: 58px;
    font-size: 9px;
  }
  .sale-tunnel-income__zone {
    flex-direction: column;
    /* margin-bottom: 30px; */
  }
  .sale-tunnel-income--before-loading__icon {
    width: 15px;
    height: 15px;
  }
  .sale-tunnel-income--before-loading__icon__zone {
    width: 20px;
    height: 20px;
  }
  .sale-tunnel-income--before-loading__span {
    width: 30px;
    font-size: 9px;
  }
  .sale-tunnel-income__detail {
    margin-bottom: 10px;
    font-size: 10px;
  }
  .sale-tunnel-income__detail__button > svg {
    width: 15px;
    height: 15px;
  }
  .sale-tunnel-income__detail__popup__square {
    bottom: calc(140% - 4px);
    width: 15px;
    height: 15px;
  }
  .sale-tunnel-income__detail__popup {
    padding: 2px;
    bottom: 140%;
  }
}
