.api-off {
  width: 100%;
  height: calc(100vh - 130px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.api-off__popup {
  background-color: var(--color-back);
  box-shadow: 0 5px 15px var(--color-div-shadow);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 20px;
}

.api-off__popup__title {
  font-weight: bold;
  padding: 8px 0;
}

.api-off__popup__icon {
  width: 50px;
  height: 50px;
  fill: var(--color-valraiso);
}

.api-off__popup__button {
  border: none;
  padding: 0;
  font-size: 20px;
  background-color: inherit;
  text-decoration: underline;
  color: var(--color-valraiso);
}

.api-off__popup__button:hover {
  cursor: pointer;
}
