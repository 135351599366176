.users-access {
  padding: 10px;
  border-radius: 8px;
  margin: 0 20px;
  background-color: var(--color-darker-nav);
  position: relative;
}

.users-access:hover {
  cursor: pointer;
}

.users-access__selector {
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  width: 200px;
  left: 230px;
  bottom: 50px;
  height: max-content;
}

.users-access__selector > button {
  border: 2px solid var(--color-darker-nav);
  background-color: var(--color-darker-nav);
  padding: 8px 0px;
}

.users-access__selector > button:hover {
  cursor: pointer;
  background-color: var(--color-nav);
  border: 2px solid var(--color-nav);
}

.users-access__selector--small {
  left: 100px;
}

@media (max-width: 450px) {
  .users-access__selector {
    bottom: 100px;
    left: 20px;
  }    
}
