.settings-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 2px;
  position: relative;
  z-index: 1;
}

.settings-input > input {
  border: none;
  min-width: 200px;
  max-width: 100%;
  padding: 4px;
  outline: none;
  font-size: 15px;
  position: relative;
  background: none;
}

.settings-input__border {
  width: 100%;
  border-bottom: 2px solid var(--color-darker-nav);
  position: relative;
}

.settings-input > input:focus + .settings-input__border {
  border-bottom: 2px solid var(--color-valraiso);
  top: 3px;
  transition: ease all 0.8s;
}

.settings-input > input::placeholder {
  font-size: 13px;
  opacity: 1;
}

.settings-input::before {
  position: absolute;
  content: attr(proposition);
  color: var(--color-font);
  top: 8px;
  left: 6px;
  z-index: 0;
}

.settings-input__span {
  opacity: 0%;
  height: 0px;
  font-size: 15px;
}
