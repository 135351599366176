.seo-popup {
  background-color: var(--color-back);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 25px;
  box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 33%);
  z-index: 2;
  position: relative;
}

.seo-popup__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.seo-popup__buttons {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 5px;
}
.seo-popup__buttons > button {
  font-weight: bold;
}

.seo-popup__content {
  display: flex;
  gap: 15px;
}

.seo-popup__content__children {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.seo-popup__settings,
.seo-popup__close {
  position: absolute;
  right: 4px;
  top: 4px;
  border: none;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: initial;
  margin: initial;
  background-color: initial;
}

.seo-popup__settings {
  right: 24px;
}

.seo-popup__close__icon {
  width: 20px;
  height: 20px;
  fill: var(--color-icon);
}

.seo-popup__close:hover > .seo-popup__close__icon {
  fill: var(--color-front);
}

.seo-popup__settings:hover > .seo-popup__close__icon {
  fill: var(--color-front);
}

@media (max-width: 695px) {
  .seo-popup {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    max-width: 95vw;
    max-height: 80vh;
  }
  .seo-popup__back {
    backdrop-filter: blur(3px);
    z-index: 25;
    position: fixed;
    top: 0px !important;
    left: 0px !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
