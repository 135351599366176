.all-filters {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
}

.all-filters__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.all-filters__filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.all-filters__choices {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 40px;
  left: -2px;
  background-color: var(--color-back);
  border: 2px solid var(--color-shadow);
  border-radius: 4px;
  max-height: 70vh;
  width: max-content;
  overflow: auto;
  z-index: 1;
}

.all-filters__choices__admin {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 40px;
  right: -120px;
  background-color: var(--color-back);
  border: 2px solid var(--color-shadow);
  border-radius: 4px;
  max-height: 0;
  width: 0;
  opacity: 0;
  z-index: 1;
}

.all-filters__choices__admin--open {
  max-height: 70vh;
  width: max-content;
  opacity: 100%;
}

.all-filters__choice {
  color: var(--color-front-light);
  border: none;
  border-radius: 0px;
  text-align: left;
}

.all-filters__choices > .all-filters__choice:first-child {
  border-start-end-radius: 3px;
  border-start-start-radius: 3px;
}

.all-filters__choices > .all-filters__choice:last-child {
  border-end-end-radius: 3px;
  border-end-start-radius: 3px;
}

.all-filters__choice:hover {
  background-color: var(--color-nav);
}

@media (max-width: 695px) {
  .all-filters__choices__admin {
    right: -108px;
  }
}

@media (max-width: 495px) {
  .all-filters__choices {
    top: 28px;
  }
  .all-filters__choice {
    font-size: 10px;
  }
  .all-filters__choices__admin {
    top: 30px;
    right: -84px;
  }
}
