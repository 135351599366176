.seo-study {
  margin-top: 20px;
  width: 90%;
  max-width: 1480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;
  box-shadow: 0 5px 15px var(--color-div-shadow);
  padding: 20px;
}

.seo-study__all-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 10px;
  gap: 15px;
}

.seo-study__switch-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.seo-study__array > tbody {
  display: block;
  max-height: calc(100vh - 340px);
  overflow: auto;
  position: relative;
  left: -5px;
}

.seo-study__array > tbody > tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.seo-study__array > thead {
  display: block;
  position: relative;
  left: -5px;
}

.seo-study__array > thead > tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.seo-study__array > thead > tr > th,
.seo-study__array > tbody > tr > th {
  width: 30%;
}
.seo-study__array > thead > tr > th + th,
.seo-study__array > tbody > tr > th + td {
  width: 12%;
  text-align: center;
}
.seo-study__array > thead > tr > th + th + th,
.seo-study__array > tbody > tr > th + td + td {
  width: 12%;
  text-align: center;
}
.seo-study__array > thead > tr > th + th + th + th,
.seo-study__array > tbody > tr > th + td + td + td {
  width: 12%;
  text-align: center;
}
.seo-study__array > thead > tr > th + th + th + th + th,
.seo-study__array > tbody > tr > th + td + td + td + td {
  width: 34%;
}

.seo-study__array .array__line {
  height: 52px;
  font-size: 17px;
}
.seo-study__array .array__top-line {
  height: 60px;
}
.seo-study__array td {
  font-size: 16px;
}
.seo-study__array th {
  font-size: 16px;
}
.seo-study .array__line__case__see-more {
  top: 8px;
}
.seo-study .array__settings__remove__button {
  top: 13px;
}

.seo-study__unique__select {
  font-size: 22px;
  font-weight: 550;
  width: 100%;
  text-align: left;
  margin-left: 11px;
}

.seo-study__select {
  font-size: 22px;
  font-weight: 550;
  max-width: 365px;
  margin-left: 11px;
}

.seo-study__title {
  font-size: 19px;
  font-weight: bold;
  text-align: left;
  color: var(--color-front);
}

@media (max-width: 695px) {
  .seo-study__array .array__line {
    height: 46px;
    font-size: 12px;
  }
  .seo-study__array .array__top-line {
    height: 54px;
  }
  .seo-study__array td {
    font-size: 11px;
  }
  .seo-study__array th {
    font-size: 11px;
  }
  .seo-study__array .array__line__case__see-more > svg {
    width: 14px;
    height: 14px;
  }
  .seo-study__array .array__line__case__see-more {
    top: inherit;
    bottom: 2px;
    padding: 2px;
  }
  .seo-study__array .array__settings__remove__button {
    top: 11px;
  }
}
