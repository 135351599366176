.search-bar {
  width: 200px;
  height: 30px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.search-bar__search {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid var(--color-ice);
  border: none;
  position: relative;
  z-index: 2;
}

.search-bar__search:hover {
  background-color: var(--color-ice-light);
  cursor: pointer;
}

.search-bar__search > svg {
  width: 24px;
  height: 24px;
  fill: var(--color-front-light);
}

.search-bar--open {
  border: 1px solid var(--color-ice);
  border-radius: 4px;
}
.search-bar--open > .search-bar__search {
  border: 1px solid var(--color-ice);
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  border-left: none;
  transition: all ease 0.3s;
}

.search-bar__input {
  position: absolute;
  top: -1px;
  left: 27px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.search-bar__input--open {
  left: 27px;
  transition: all ease 0.3s;
}

.search-bar__input--close {
  left: -140px;
  transition: all ease 0.3s;
}

.search-bar__input > input {
  width: 172px;
  height: 32px;
  border: none;
  padding-left: 8px;
  font-size: 15px;
  position: relative;
  background: none;
}

.search-bar__delete {
  position: absolute;
  right: 0px;
  z-index: 3;
  width: 16px;
  height: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
}

.search-bar__delete > svg {
  width: 14px;
  height: 14px;
  fill: var(--color-icon);
}

.search-bar__delete:hover > svg {
  fill: var(--color-front-light);
}

.search-bar__input::before {
  position: absolute;
  content: attr(proposition);
  color: var(--color-font);
  top: 8px;
  left: 8px;
  z-index: 0;
  font-size: 15px;
  font-weight: 400;
}
