.sale-tunnel-bloc {
  position: relative;
  min-width: 120px;
  box-shadow: 0 5px 15px rgba(200, 200, 200, 65%);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: left;
  padding: 8px 8px 8px 10px;
  background-color: var(--color-back);
  z-index: 1;
}

.sale-tunnel-bloc__title {
  font-size: 15px;
  font-weight: 300;
  color: var(--color-icon);
  text-align: left;
}

.sale-tunnel-bloc__under {
  position: absolute;
  top: calc(100% + 4px);
  color: rgb(0, 90, 246);
  width: 100%;
  left: 0px;
  font-size: 13px;
}

.sale-tunnel-bloc__stat {
  font-size: 27px;
  text-align: left;
  font-weight: bold;
  color: var(--color-pos);
}

.sale-tunnel-bloc__numbers {
  font-size: 15px;
  text-align: left;
  color: var(--color-icon);
}

.sale-tunnel__schema__error {
  box-shadow: none;
}

.sale-tunnel__schema__error:hover {
  cursor: pointer;
}

.sale-tunnel__schema__error__button {
  width: 120px;
  height: 120px;
  padding: none;
  margin: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(200, 200, 200, 65%);
  background-color: var(--color-back);
  position: relative;
  z-index: 1;
}

.sale-tunnel__schema__error__button:hover {
  box-shadow: 0 5px 15px var(--color-primary);
}

.sale-tunnel__schema__error__button:hover {
  box-shadow: 0 5px 15px var(--color-primary);
}

.sale-tunnel__schema__error__button:active {
  box-shadow: 0 5px 15px var(--color-primary);
}

.sale-tunnel-bloc__button {
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: none;
  background-color: initial;
}

.sale-tunnel-bloc__icon {
  fill: var(--color-icon);
  width: 30px;
  height: 30px;
}

.sale-tunnel-bloc__button:hover > .sale-tunnel-bloc__icon {
  fill: var(--color-front);
}

@media (max-width: 945px) {
  .sale-tunnel__schema__error__button {
    width: 90px;
    height: 90px;
  }
  .sale-tunnel-bloc {
    min-width: 90px;
    padding: 4px;
  }

  .sale-tunnel-bloc__title {
    font-size: 13px;
  }

  .sale-tunnel-bloc__stat {
    font-size: 25px;
  }

  .sale-tunnel-bloc__numbers {
    font-size: 12px;
  }
  .sale-tunnel__schema__error__button {
    width: 90px;
    height: 90px;
  }
  .sale-tunnel-bloc__button {
    top: -6px;
    right: -6px;
    width: 20px;
    height: 20px;
  }
  .sale-tunnel-bloc__icon {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 695px) {
  .sale-tunnel-bloc {
    min-width: 75px;
    height: max-content;
    padding: 4px;
  }
  .sale-tunnel-bloc__title {
    font-size: 12px;
    max-width: 95px;
  }
  .sale-tunnel-bloc__stat {
    font-size: 22px;
  }
  .sale-tunnel-bloc__numbers {
    font-size: 11px;
  }
  .sale-tunnel__schema__error__button {
    width: 90px;
    height: 90px;
  }
  .sale-tunnel-bloc__button {
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
  }
  .sale-tunnel-bloc__icon {
    width: 20px;
    height: 20px;
  }
  .sale-tunnel-bloc__under {
    font-size: 9px;
  }
}

@media (max-width: 495px) {
  .sale-tunnel-bloc {
    min-width: 65px;
    padding: 3px;
  }
  .sale-tunnel-bloc__title {
    font-size: 10px;
    max-width: 85px;
  }
  .sale-tunnel-bloc__stat {
    font-size: 19px;
  }
  .sale-tunnel-bloc__numbers {
    font-size: 10px;
  }
}
