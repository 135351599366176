.top-scroller {
  display: none;
}

@media (max-width: 695px) {
  .top-scroller {
    position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-image: var(--color-primary);
    z-index: 5;
    bottom: 20px;
    right: 20px;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top-scroller > svg {
    fill: var(--color-valraiso);
    fill: var(--color-back);
  }
}
