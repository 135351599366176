.admin-filter {
  position: relative;
  z-index: 5;
}

.admin-filter > button {
  background-color: var(--color-nav) !important;
  color: var(--color-front);
}

.admin-filter__button {
  border: none;
  display: flex;
  padding: 4px !important;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.admin-filter__button__domain {
  margin-left: 4px;
  font-size: 16px;
}

.admin-filter__button > span {
  margin-right: 0px;
}

.admin-filters__icon {
  width: 20px;
  height: 20px;
}

.admin-filter__button:hover {
  box-shadow: 0 0 0 2px var(--color-primary);
}

.admin-filters__searchbar {
  border-bottom: 2px solid var(--color-shadow);
  display: flex;
  padding: 4px;
  gap: 4px;
  align-items: center;
}

.admin-filter__input {
  background-color: var(--color-back);
  border: none;
  color: var(--color-front);
  outline: none;
}

.admin-filter__input::placeholder {
  color: rgb(215, 215, 215);
}

@media (prefers-color-scheme: dark) {
  .admin-filter__input::placeholder {
    color: rgb(75, 75, 75);
  }
}

@media (max-width: 695px) {
  .admin-filter__button__domain {
    font-size: 12px;
  }
}

@media (max-width: 495px) {
  .admin-filter__button {
    min-height: 30px !important;
    min-width: 30px !important;
    height: 30px !important;
    width: 30px !important;
  }
  .admin-filter__button__domain {
    margin-left: 0px;
    width: 0px;
    opacity: 0%;
  }
  .admin-filters__icon {
    height: 24px !important;
    width: 24px !important;
  }
}
