.seo-popup-glimpse {
  border: 1px solid var(--color-ice);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background-color: var(--color-ice-light);
  position: relative;
}

.seo-popup-glimpse > span {
  position: absolute;
  top: 2px;
  left: 10px;
  font-size: 13px;
  color: #828283;
}