.sale-tunnel-origin__tab {
  width: 100%;
  height: calc(100% - 45px);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.sale-tunnel-origin__stats {
  display: flex;
  flex-direction: column;
}

.sale-tunnel-origin__line {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.sale-tunnel-origin__cell {
  display: flex;
  align-items: center;
  width: 360px;
  text-align: left;
  height: 40px;
  vertical-align: middle;
  font-size: 14px;
}

.sale-tunnel-origin__cell__numbers {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 105px;
  text-align: right;
  height: 40px;
  vertical-align: middle;
  font-size: 14px;
  padding-right: 5px;
}

.sale-tunnel-origin__cell__numbers + .sale-tunnel-origin__cell__numbers {
  justify-content: right;
  padding-left: 5px;
}

.sale-tunnel-origin__group__title {
  text-align: left;
  background-color: var(--color-shadow);
  padding: 4px 0;
}

.sale-tunnel-origin__group {
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.sale-tunnel-origin__tab:nth-child(2)
  > .sale-tunnel-origin__group
  > .sale-tunnel-origin__stats
  > .sale-tunnel-origin__line
  > .sale-tunnel-origin__cell,
.sale-tunnel-origin__tab:nth-child(2)
  > .sale-tunnel-origin__group
  > .sale-tunnel-origin__stats
  > .sale-tunnel-origin__line
  > .sale-tunnel-origin__cell__numbers {
  color: var(--color-icon);
  font-size: 12px;
  height: 25px;
}

.sale-tunnel-origin__stats-zone {
  overflow-y: auto;
}

.sale-tunnel-origin > .comparative-select__button,
.sale-tunnel-origin > .comparative-select__button {
  width: max-content;
  text-align: left;
  margin-left: 50px;
  border-color: var(--color-valraiso);
}

.sale-tunnel-origin > .comparative-select__button:hover,
.sale-tunnel-origin > .comparative-select__button:hover {
  cursor: auto;
}

.sale-tunnel-origin__close__button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 35px;
  height: 35px;
  border: 2px solid var(--color-shadow);
}

.sale-tunnel-origin__close__button:hover {
  border-color: var(--color-valraiso);
}

.sale-tunnel-origin__close__icon {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 25px;
  height: 25px;
  fill: var(--color-icon);
}

@media (prefers-color-scheme: dark) {
  .sale-tunnel-origin__group__title {
    background-color: var(--color-dark-back);
  }
}

@media (max-width: 945px) {
  .sale-tunnel-origin__hide-zone {
    position: absolute;
    left: -11px;
    top: 0px;
    height: 345px;
    width: 430px;
    z-index: 2;
    overflow: hidden;
  }
  .sale-tunnel-origin {
    height: 345px;
    width: 430px;
    left: -430px;
  }
  .sale-tunnel-origin__tab {
    padding: 8px 10px 8px 15px;
    height: calc(100% - 30px);
  }
  .sale-tunnel-origin__cell {
    width: 250px;
    font-size: 13px;
  }
  .sale-tunnel-origin__cell__numbers {
    height: 40px;
    font-size: 13px;
  }
  .sale-tunnel-origin__close__button {
    width: 30px;
    height: 30px;
  }
  .sale-tunnel-origin__close__icon {
    width: 21px;
    height: 21px;
  }
}

@media (max-width: 695px) {
  .sale-tunnel-origin__hide-zone {
    position: absolute;
    left: -55px;
    top: 0px;
    height: 327px;
    width: 400px;
    z-index: 2;
    overflow: hidden;
  }
  .sale-tunnel-origin {
    height: 327px;
    width: 400px;
    left: -400px;
  }
  .sale-tunnel-origin__stats {
    margin-left: 20px;
  }
  .sale-tunnel-origin__tab {
    padding: 8px 5px 8px 15px;
    height: calc(100% - 20px);
  }
  .sale-tunnel-origin__cell {
    width: 230px;
    font-size: 12px;
  }
  .sale-tunnel-origin__cell__numbers {
    height: 40px;
    font-size: 12px;
  }
  .sale-tunnel-origin__group__title {
    font-size: 13px;
  }
  .sale-tunnel-origin > .comparative-select__button {
    margin-left: 30px;
  }
  .sale-tunnel-origin__close__button {
    width: 25px;
    height: 25px;
  }
  .sale-tunnel-origin__close__icon {
    top: 2px;
    right: 2px;
    width: 18px;
    height: 18px;
  }
  .sale-tunnel-origin__cell__numbers
    + .sale-tunnel-origin__cell__numbers::after {
    font-size: 10px;
  }
}

@media (max-width: 495px) {
  .sale-tunnel-origin__hide-zone {
    position: absolute;
    left: -30px;
    top: 0px;
    height: 327px;
    width: 330px;
    z-index: 2;
    overflow: hidden;
  }
  .sale-tunnel-origin {
    height: 327px;
    width: 330px;
    left: -330px;
  }
  .sale-tunnel-origin__stats {
    margin-left: 10px;
  }
  .sale-tunnel-origin__tab {
    padding: 8px 5px 8px 15px;
    height: calc(100% - 20px);
  }
  .sale-tunnel-origin__cell {
    width: 180px;
    font-size: 12px;
  }
  .sale-tunnel-origin__cell__numbers {
    height: 40px;
    font-size: 12px;
  }
  .sale-tunnel-origin__group__title {
    font-size: 13px;
  }
  .sale-tunnel-origin > .comparative-select__button {
    margin-left: 20px;
  }
}
