.switch-button {
  display: flex;
  border-radius: 8px;
  gap: 2px;
  width: 200px;
  height: max-content;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid var(--color-ice-light);
  position: relative;
  overflow: hidden;
  background-color: var(--color-ice-light);
  box-shadow: 0px 0px 0px 1px var(--color-ice);
}

.switch-button__button {
  border: none;
  background: none;
  border-radius: none;
  padding: 8px;
  width: 50%;
  position: relative;
  z-index: 1;
  font-weight: bold;
  color: var(--color-front-light);
}

.switch-button__back {
  position: absolute;
  background-color: var(--color-back);
  width: calc(50% - 2px);
  height: 36px;
}

.switch-button__back--right {
  color: var(--color-front);
  left: 50%;
  border-end-end-radius: 6px;
  border-start-end-radius: 6px;
  transition: all ease 0.5s;
}

.switch-button__back--left {
  color: var(--color-front);
  left: calc(0% + 2px);
  border-start-start-radius: 6px;
  border-end-start-radius: 6px;
  transition: all ease 0.5s;
}

.switch-button__back--right + button {
  color: var(--color-font);
}

.switch-button__back--left + button + button {
  color: var(--color-font);
}
