.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.loaderbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 2;
}

.site-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.page-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}
