.seo-popup-rank-infos--left {
  text-align: left;
}

.seo-popup-rank-infos--flex {
  display: flex;
  flex-direction: column;
}

.seo-popup-rank-infos--gap {
  gap: 5px;
}

.seo-popup-rank-infos--normal {
  font-size: 13px;
}

.seo-popup-rank-infos--smaller {
  font-size: 11px;
}

.seo-popup-rank-infos--urls {
  text-decoration: underline;
  color: #003fff;
}

.seo-popup-rank-infos--urls:hover {
  cursor: pointer;
}

.seo-popup-rank-infos--urls--off {
  color: var(--color-icon);
}

.seo-popup-rank-infos__sitelinks {
  display: flex;
}

.seo-popup-set-kind--gap {
  gap: 4px;
}

.seo-popup-set-kind__input {
  font-size: 13px !important;
}

label[class^="select_label"] {
  font-size: 12px;
  color: #5c5c5c;
}

select[class^="select_field"] {
  appearance: auto;
}

.seo-popup-keyword__title {
  font-weight: bold;
  margin-bottom: 20px;
}

.seo-popup-badges {
  display: none;
}

.seo-popup--bold {
  font-weight: bold;
}

.seo-popup__set__kind {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.seo-popup__set__kind__checkbox > span {
  font-weight: initial;
  position: relative;
  top: 2px
}

@media (min-width: 696px) {
  .seo-ranks__badges__ads--on + div > .seo-popup-badges,
  .seo-ranks__badges--on:hover + div > .seo-popup-badges {
    display: inherit;
    position: absolute;
    z-index: 5;
  }
}

@media (max-width: 695px) {
  .seo-ranks__badges__ads--on + div > .seo-popup-badges {
    display: inherit;
    position: absolute;
    z-index: 15;
  }
}

.seo-ranks__ads {
  width: 350px;
  display: flex;
}

.seo-ranks__ads__button {
  border-radius: 0;
  padding: 6px 7px;
  width: max-content;
  border: 1px solid var(--color-darker-nav);
  border-right: none;
  position: relative;
  z-index: 1;
  font-size: 15px;
  background-color: var(--color-nav);
}

.seo-ranks__ads__button::after {
  content: attr(date);
  font-size: 10px;
  position: relative;
  left: 3px;
  color: var(--color-front-light);
}

.seo-ranks__ads__button:last-of-type {
  border-right: 1px solid var(--color-darker-nav);
  border-top-right-radius: 4px;
}
.seo-ranks__ads__button:first-of-type {
  border-top-left-radius: 4px;
}

.seo-ranks__ads__button--selected {
  border-bottom: 1px solid var(--color-back);
  background-color: 1px solid var(--color-back);
}

.seo-popup-ads {
  border: 1px solid var(--color-darker-nav);
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  top: -1px;
  z-index: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  gap: 3px;
}

.seo-popup-ads__link__preview {
  position: absolute;
  background-color: var(--color-back);
  display: flex;
  align-items: center;
  top: 17px;
  display: none;
  gap: 6px;
  border: 2px solid var(--color-ice);
  height: calc(100% + 20px);
  border-radius: 6px;
  z-index: 1;
  max-width: 350px;
  overflow: hidden;
  padding-right: 8px;
}

.seo-popup-ads__link__preview:hover,
.seo-popup-ads__link:hover + .seo-popup-ads__link__preview {
  display: flex;
}

.seo-popup-ads__popup__overflow {
  overflow: unset;
}

.seo-popup-ads__link__preview__image {
  border-right: 2px solid var(--color-ice);
  border-top-right-radius: 25%;
  border-bottom-right-radius: 25%;
  overflow: hidden;
}
