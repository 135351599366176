.seo-ads-impact {
  margin-top: 20px;
  width: 90%;
  max-width: 1480px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* box-shadow: 0 5px 15px var(--color-div-shadow); */
  padding: 20px;
  gap: 30px;
}


.seo-ads-impact__controler {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.seo-ads-impact__controler > button{
  width: 100%;
}

.seo-ads-impact__legend {
  width: 70%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.seo-ads-impact__legend__line {
  display: flex;
  gap: 10px;
  align-items:baseline;
}

.seo-ads-impact__legend__line__color {
  width: 13px;
  height: 13px;
}

.seo-ads-impact__selection {
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
