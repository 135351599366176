.popup-front__backdrop {
  backdrop-filter: blur(3px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0px;
  height: 0px;
  z-index: 10;
}

.popup-front__backdrop--open {
  width: 100%;
  height: 100%;
}