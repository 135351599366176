.date-comparison-bloc {
  min-width: 100px;
  min-height: 100px;
  box-shadow: 0 5px 15px rgba(200, 200, 200, 65%);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.date-comparison-bloc__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
}

.date-comparison-bloc__title {
  max-width: 95px;
  font-size: 15px;
  font-weight: 300;
  text-align: right;
}

.date-comparison-bloc__stat {
  font-size: 24px;
  text-align: right;
}

.date-comparison-bloc__numbers {
  font-size: 12px;
  text-align: right;
  color: var(--color-icon);
}

.date-comparison-blocs__message {
  padding: 10px;
  font-size: 13px;
}

.date-no-comparison-bloc {
  min-width: 100px;
  min-height: 100px;
  box-shadow: 0 5px 15px rgba(200, 200, 200, 65%);
  border-radius: 6px;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  transform: rotateX(180deg);
  animation: example 0.6s ease forwards;
}

.date-no-comparison-bloc__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: flex-start;
}

.date-no-comparison-bloc__title {
  max-width: 88px;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: var(--color-icon);
}

.date-no-comparison-bloc__number {
  font-size: 21px;
  text-align: left;
  font-weight: bold;
}

@keyframes example {
  0% {
    transform: rotateX(180deg);
    color: var(--color-back);
  }
  25% {
    transform: rotateX(225deg);
    color: var(--color-back);
  }
  50% {
    transform: rotateX(270deg);
    color: var(--color-front);
  }
  75% {
    transform: rotateX(315deg);
  }
  100% {
    transform: rotateX(360deg);
    color: var(--color-front);
  }
}

.date-no-comparison-bloc-icon {
  background-color: #FFD8CC;
  fill: #FF3C00;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding: 8px;
}

.date-comparison-bloc:nth-child(2) > .date-no-comparison-bloc-icon,
.date-no-comparison-bloc:nth-child(2) > .date-no-comparison-bloc-icon {
  background-color: #FFE6EF;
  fill: #FF005C;
}

.date-comparison-bloc:nth-child(3) > .date-no-comparison-bloc-icon,
.date-no-comparison-bloc:nth-child(3) > .date-no-comparison-bloc-icon {
  background-color: #EFEAFD;
  fill: #5F2EEA;
}

.date-comparison-bloc:nth-child(4) > .date-no-comparison-bloc-icon,
.date-no-comparison-bloc:nth-child(4) > .date-no-comparison-bloc-icon {
  background-color: #CCD9FF;
  fill: #003FFF;
}

@media (max-width: 945px) {
  .date-comparison-bloc {
    min-width: 100px;
    min-height: 100px;
    padding: 8px;
  }
  .date-comparison-bloc__content {
    gap: 7px;
  }
  .date-comparison-bloc__title {
    width: 100px;
    font-size: 15px;
  }
  .date-comparison-bloc__stat {
    font-size: 23px;
  }
  .date-comparison-bloc__numbers {
    font-size: 10px;
  }
  .date-no-comparison-bloc__content {
    gap: 6px;
  }
  .date-no-comparison-bloc__title {
    width: 100px;
    font-size: 15px;
  }
  .date-no-comparison-bloc__number {
    font-size: 20px;
  }
  .date-comparison-blocs__message {
    font-size: 11px;
  }
}

@media (max-width: 695px) {
  .date-comparison-blocs__message {
    font-size: 10px;
  }
  .date-no-comparison-bloc-icon {
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 495px) {
  .date-comparison-bloc {
    min-width: 100px;
    min-height: 100px;
    padding: 8px;
  }
  .date-comparison-bloc__content {
    gap: 5px;
  }
  .date-comparison-bloc__title {
    width: 75px;
    font-size: 12px;
  }
  .date-comparison-bloc__stat {
    font-size: 20px;
    text-align: right;
  }
  .date-comparison-bloc__numbers {
    font-size: 8px;
    text-align: right;
    color: var(--color-icon);
  }
  .date-no-comparison-bloc__content {
    gap: 5px;
  }
  .date-no-comparison-bloc__title {
    width: 75px;
    font-size: 12px;
  }
  .date-no-comparison-bloc__number {
    font-size: 16px;
  }
  .date-comparison-blocs__message {
    font-size: 8px;
    margin-top: -20px;
  }
}
