.settings-select {
  border: none;
  font-size: 15px;
  color: var(--color-front);
  padding-left: 0;
  position: relative;
  left: -9px;
  padding: 5px;
  border-radius: 4px;
  background-color: var(--color-back);
}

.settings-select:hover {
  background-color: var(--color-nav);
  cursor: pointer;
}
