.seo-select-date__label {
  align-self: center;
  width: 200px;
  position: relative;
  z-index: 1;
  height: 48px;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 1px var(--color-ice);
  border: none;
  display: flex;
  align-items: center;
  background-color: var(--color-ice-light);
  overflow: hidden;
  margin-bottom: 20px;
}

.seo-select-date {
  align-self: center;
  width: 100%;
  height: 100%;
  padding: 20px 4px 4px 10px;
  border-radius: 8px;
  background-color: transparent;
  border: none;
  font-size: 13px;
}

.seo-select-date__label--on::after {
  position: absolute;
  content: attr(label);
  top: 4px;
  left: 8px;
  font-size: 11px;
  color: #5c5c5c;
  opacity: 75%;
  z-index: -1;
}

.seo-select-date__label--off::after {
  position: absolute;
  align-self: center;
  width: 100%;
  z-index: -1;
  content: attr(title);
  font-size: 18px;
  color: #5c5c5c;
  opacity: 75%;
}

.seo-select-date__backdrop {
  position: absolute;
  top: -20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-ice-light);
  box-shadow: 0 5px 15px var(--color-div-shadow);
}

.seo-select-date__backdrop > div {
  opacity: 0%;
}

.seo-select-date__loader-bar {
  margin-bottom: 20px;
  width: 100%;
  height: max-content;
}
