.export-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6px 10px;
  background: none;
  color: var(--color-front-light);
}

.export-button:hover {
  cursor: pointer;
}

.export-button > svg {
  fill: var(--color-front-light);
  width: 20px;
  height: 20px;
}

@media (max-width: 495px) {
  .export-button {
    font-size: 13px;
  }
  .export-button > svg {
    fill: var(--color-front-light);
    width: 16px;
    height: 16px;
  }
}
