.seo-study-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.seo-study-chart__tooltip {
  background-color: var(--color-back);
  padding: 5px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(200, 200, 200, 45%);
  border-radius: 8px;
}

.seo-study-chart__tooltip__date {
  font-weight: 550;
}

.seo-study-chart__tooltip__number {
  color: var(--color-valraiso);
}

.seo-study-chart__tooltip__keyword {
  color: var(--color-front-light);
}