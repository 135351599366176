.navigation-menu {
  background-color: var(--color-back);
  height: 100%;
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 1;
}

.navigation-menu__pages {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.menu-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 5px;
  width: calc(100% - 12px);
  margin-left: 12px;
}

.menu-button:hover {
  cursor: pointer;
}

.menu-button__button {
  border: none;
  background-color: initial;
  font-size: 16px;
  padding: 6px 6px 6px 0px;
  text-align: left;
  display: flex;
  align-items: center;
}

.menu-button__button:hover {
  cursor: pointer;
}

.menu-button__icon {
  background-color: var(--color-darker-nav);
  padding: 8px;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  fill: var(--color-front);
}

.menu-button__icon__arrow {
  margin-left: 8px;
  width: 16px;
  height: 16px;
  border-radius: 6px;
  fill: var(--color-front);
}

.menu-button--on > .menu-button__icon {
  background-image: var(--color-primary);
  fill: var(--color-back);
}

.menu-button--on > .menu-button__button {
  font-weight: bold;
}

.menu-button:hover > .menu-button__button {
  background-color: var(--color-nav);
}

.menu-button:hover > .menu-button__icon__arrow {
  background-color: var(--color-nav);
}

.plausible-dashboard {
  max-width: 1100px;
  width: 90%;
  height: calc(100vh - 69px);
  margin-bottom: 4px;
}

.plausible-dashboard > iframe {
  width: 100%;
  height: 100%;
}

.navigation-menu__back {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px) !important;
}

.navigation-menu__pages__hider {
  position: sticky;
  top: 65px;
  background-color: var(--color-back);
  height: calc(100vh - 65px);
  box-shadow: 4px 2px 6px -2px var(--color-div-shadow);
  z-index: 5;
  overflow-y: auto;
}

.navigation-menu--open {
  width: 260px;
  min-width: 260px;
  transition: all ease 0.3s;
}

.navigation-menu--close {
  width: 67px;
  min-width: 67px;
  transition: all ease 0.3s;
}

@media (max-width: 695px) {
  .navigation-menu--close {
    width: 0px;
    min-width: 0px;
  }
  .navigation-menu__pages__hider {
    position: fixed;
    left: 0px;
    top: 65px;
  }
}
