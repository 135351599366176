.sale-tunnel {
  max-width: calc(var(--max-width) - 50px);
  height: max-content;
  box-shadow: 0 5px 15px var(--color-div-shadow);
  position: relative;
  border-radius: 4px;
}

.sale-tunnel__schema {
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
  margin: 20px 10px;
}

.sale-tunnel__schema::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 25%;
  border: 2px dashed var(--color-font);
  width: 70%;
}

.sale-tunnel__schema::before {
  content: "";
  position: absolute;
  top: 20%;
  right: 33%;
  border: 2px dashed var(--color-font);
  height: 60%;
}

.sale-tunnel__background-stick {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.sale-tunnel__background-stick::after {
  content: "";
  position: absolute;
  top: 20%;
  right: 23%;
  border: 2px dashed var(--color-font);
  width: 10%;
}

.sale-tunnel__background-stick::before {
  content: "";
  position: absolute;
  top: 80%;
  right: 23%;
  border: 2px dashed var(--color-font);
  width: 10%;
}

.sale-tunnel__schema__end {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 25px;
}

@media (max-width: 945px) {
  .sale-tunnel {
    min-height: 390px;
  }
  .sale-tunnel__schema {
    gap: 10px;
    margin: 0 11px;
    padding: 0 8px 0px 8px;
  }
  .sale-tunnel__schema__end {
    gap: 15px;
  }
}

@media (max-width: 695px) {
  .sale-tunnel {
    height: auto;
  }
  .sale-tunnel__switch-direction {
    width: 100%;
    display: flex;
  }
  .sale-tunnel__schema {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 10px 20px 0 20px;
  }
  .sale-tunnel__schema__end {
    flex-direction: row;
    gap: 20px;
  }
  .sale-tunnel__schema::after {
    top: inherit;
    bottom: 30%;
    right: 50%;
    width: 0px;
    height: 60%;
    border: 1px dashed var(--color-font);
  }
  .sale-tunnel__schema::before {
    top: inherit;
    bottom: 40%;
    height: 0px;
    width: 70%;
    right: 15%;
    border: 1px dashed var(--color-font);
  }
  .sale-tunnel__background-stick::before {
    right: 15%;
    top: inherit;
    bottom: 30%;
    width: 0px;
    height: 10%;
    border: 1px dashed var(--color-font);
  }
  .sale-tunnel__background-stick::after {
    right: 85%;
    bottom: 30%;
    top: inherit;
    width: 0px;
    height: 10%;
    border: 1px dashed var(--color-font);
  }
}

@media (max-width: 495px) {
  .sale-tunnel__schema {
    margin: 0 10px;
  }
  .sale-tunnel__schema__end {
    gap: 15px;
  }
}
