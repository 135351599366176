.comparative-line__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  font-size: 14px;
  width: 100%;
}

.comparative-line__row--odd {
  background-color: var(--color-nav);
}

.comparative-line__cell__num,
.comparative-line__cell__spe,
.comparative-line__cell__vu,
.comparative-line__cell__pv,
.comparative-line__cell__tdc,
.comparative-line__cell__tdp,
.comparative-line__cell__nbr-command,
.comparative-line__cell__ca {
  display: table-cell;
  vertical-align: middle;
}

.comparative-line__cell__num {
  padding-left: 20px;
  text-align: left;
  font-weight: bold;
  width: 10%;
}

.comparative-line__cell__spe {
  text-align: left;
  color: var(--color-valraiso);
  font-weight: bold;
  width: 29%;
  overflow: auto;
}

.comparative-line__cell__spe__clickable:hover {
  cursor: pointer;
}

.comparative-line__cell__nbr-command {
  text-align: left;
  width: 11%;
}

.comparative-line__cell__vu {
  text-align: left;
  width: 11%;
}
.comparative-line__cell__tdc {
  text-align: left;
  width: 10%;
}

.comparative-line__cell__ca::after,
.comparative-line__cell__vu::after {
  content: attr(percentage);
  font-size: 11px;
}

.comparative-line__cell__pv {
  text-align: left;
  width: 8%;
}

.comparative-line__cell__ca {
  text-align: left;
  width: 16%;
}

.comparative-line__cell__tdp {
  text-align: left;
  width: 13%;
}

.comparative-line__cell__tdp__data::before {
  content: attr(success);
  color: var(--color-pos);
}
.comparative-line__cell__tdp__data::after {
  content: attr(failure);
  color: var(--color-neg);
}

.comparative-line__cell__ca {
  padding-right: 20px;
}

.comparative-line__row:nth-child(2) > .comparative-line__cell__num,
.comparative-line__row:nth-child(2) > .comparative-line__cell__spe,
.comparative-line__row:nth-child(2) > .comparative-line__cell__vu,
.comparative-line__row:nth-child(2) > .comparative-line__cell__pv,
.comparative-line__row:nth-child(2) > .comparative-line__cell__tdc,
.comparative-line__row:nth-child(2) > .comparative-line__cell__tdp,
.comparative-line__row:nth-child(2) > .comparative-line__cell__nbr-command,
.comparative-line__row:nth-child(2) > .comparative-line__cell__ca {
  color: gray;
  font-weight: normal;
  font-size: 11px;
}

.comparative-line__row:nth-child(2) > .comparative-line__cell__vu::before {
  content: "Visiteurs";
}
.comparative-line__row:nth-child(2) > .comparative-line__cell__pv::after {
  content: "Pages";
}
.comparative-line__row:nth-child(2) > .comparative-line__cell__tdc::after {
  content: "Taux de conversion";
}
.comparative-line__row:nth-child(2) > .comparative-line__cell__tdp::after {
  content: "Succes / echecs";
}
.comparative-line__row:nth-child(2)
  > div
  > .comparative-line__cell__nbr-command::after {
  content: "Commandes";
}
.comparative-line__row:nth-child(2) > div > .comparative-line__cell__ca::after {
  content: "Chiffre d'affaire";
}

.comparative-line__row:nth-child(2)
  > div
  > .comparative-line__cell__nbr-command,
.comparative-line__row:nth-child(2) > div > .comparative-line__cell__ca {
  text-align: left;
  color: gray;
  font-weight: normal;
  font-size: 11px;
}
.comparative-line__row:nth-child(2)
  > div
  > .comparative-line__cell__nbr-command {
  width: 41%;
}
.comparative-line__row:nth-child(2) > div > .comparative-line__cell__ca {
  width: 59%;
}

.comparative-line__row:nth-child(2) {
  height: 45px;
}

.comparative-line__cell__spe--small {
  width: 42%;
}

.comparative-line__cell__spe--big {
  width: 52%;
}

@media (max-width: 945px) {
  .comparative-line__row {
    height: 55px;
    font-size: 13px;
  }
  .comparative-line__cell__num {
    padding-left: 10px;
  }
  .comparative-line__cell__ca {
    padding-right: 10px;
  }
  .comparative-line__row:nth-child(2) > .comparative-line__cell__num,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__spe,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__vu::before,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__pv::after,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__tdc::after,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__tdp::after,
  .comparative-line__row:nth-child(2)
    > div
    > .comparative-line__cell__nbr-command::after,
  .comparative-line__row:nth-child(2)
    > div
    > .comparative-line__cell__ca::after {
    font-size: 10px;
  }
  .comparative-line__row:nth-child(2) {
    height: 30px;
  }
}

@media (max-width: 695px) {
  .comparative-line__row {
    height: 45px;
    font-size: 9px;
  }
  .comparative-line__cell__num {
    padding-left: 5px;
  }
  .comparative-line__cell__ca {
    padding-right: 5px;
  }
  .comparative-line__row:nth-child(2) > .comparative-line__cell__num,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__spe,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__vu::before,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__pv::after,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__tdc::after,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__tdp::after,
  .comparative-line__row:nth-child(2)
    > div
    > .comparative-line__cell__nbr-command::after,
  .comparative-line__row:nth-child(2)
    > div
    > .comparative-line__cell__ca::after {
    font-size: 8px;
  }
  .comparative-line__row:nth-child(2) {
    height: 25px;
  }
  .comparative-line__row:nth-child(2) > .comparative-line__cell__vu::before {
    content: "VU";
  }
  .comparative-line__row:nth-child(2) > .comparative-line__cell__pv::after {
    content: "P";
  }
  .comparative-line__row:nth-child(2) > .comparative-line__cell__tdc::after {
    content: "TDC";
  }
  .comparative-line__row:nth-child(2) > .comparative-line__cell__tdp::after {
    content: "S/E";
  }
  .comparative-line__row:nth-child(2)
    > div
    > .comparative-line__cell__nbr-command::after {
    content: "Cdes";
  }
  .comparative-line__row:nth-child(2)
    > div
    > .comparative-line__cell__ca::after {
    content: "CA";
  }
  .comparative-line__cell__ca::after,
  .comparative-line__cell__vu::after {
    font-size: 7px;
  }
}

@media (max-width: 495px) {
  .comparative-line__row {
    font-size: 8px;
  }
  .comparative-line__row:nth-child(2) > .comparative-line__cell__num,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__spe,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__vu::before,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__pv::after,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__tdc::after,
  .comparative-line__row:nth-child(2) > .comparative-line__cell__tdp::after,
  .comparative-line__row:nth-child(2)
    > div
    > .comparative-line__cell__nbr-command::after,
  .comparative-line__row:nth-child(2)
    > div
    > .comparative-line__cell__ca::after {
    font-size: 7px;
  }
  .comparative-line__cell__ca::after,
  .comparative-line__cell__vu::after {
    font-size: 6px;
  }
}
